import React, { useEffect, useState ,useRef } from "react";
import classes from "./Common.module.css";
import ProfileCard from "./ProfileCard";
import ConnectionService from "../../../../../../Services/ConnectionService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import Loader from "../../../../../GlobleShared/Loader/Loader";

const Invitations = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginated, setPaginated] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [isLoading, setLsLoading] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const [confirmationText, setConfirmationText] = useState('')
  const [connectionId, setConnectionId] = useState();
  const [totalCount, setTotalCount] = useState();
  const initialized = useRef(false)
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getInvitationLIst(page);
    }
    // return () => {
    //   getInvitationLIst(page);
    // };
  }, []);

  const getInvitationLIst = (pageNum) => {
    let data = {
      limit: limit,
      page: pageNum,
      paginated: paginated,
      // searchTerm: searchTerm,
    };
    setLsLoading(true)
    ConnectionService.getInvitationList(data)
      .then((res) => {
        setProfiles([...profiles,...res.list]);
        setTotalCount(res?.totalCount)
        setLsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const refreshInvitationLIst = (pageNum) => {
    let data = {
      limit: limit*pageNum,
      page: 1,
      paginated: paginated,
      // searchTerm: searchTerm,
    };
    setLsLoading(true)
    ConnectionService.getInvitationList(data)
      .then((res) => {
        setProfiles([...res.list]);
        setTotalCount(res.totalCount)
        setLsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const updateStatus = (connectionIdValue, statusId) => {
    let data = {
      connectionId: connectionIdValue,
      connectionStatusId: statusId,
    };
    setLsLoading(true)
    ConnectionService.updateConnection(data)
      .then((res) => {
        refreshInvitationLIst(page);
        setLsLoading(false);
        toast.success(res.message);
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  };

  const handleAction = (connectionId, action) => {
    setConnectionId(connectionId);
    setModalAction(action);
    setConfirmationText(`Are you sure you want to ${action.toLowerCase()} the selected contact?`);
    setShowModal(true);
  };
  const handleConfirmation = () => {
    if (modalAction === "Accept") {
      updateStatus(connectionId, 2);
    } else if (modalAction === "Dismiss") {
      updateStatus(connectionId, 3);
    }
    setShowModal(false);
  };
  const closeModalHandler = () => {
    setShowModal(false);
    setConfirmationText("");
    setModalAction("");
  }

  const viewMore = () => {
    getInvitationLIst(page +1);
    setPage(page +1);
  }

  return (
    <div>
      {profiles.length > 0 && (
        <div className={classes.containers}>
          {profiles.map((profile) => (
            <ProfileCard
              key={profile.rowNum}
              connectionsProfileDetails={profile}
              buttonShow={true}
              handleAction={handleAction}
            />
          ))}
        </div>
      )}

      {(totalCount > profiles?.length && profiles.length > 0) && (
        <div className="text-center mt-2 mb-2">
          <button onClick={viewMore} className={classes['view-more']}>View more</button>
        </div>
      )}

      {profiles.length === 0 && (
        <div className={classes["no-data"]}>
          <p>
            No Invitation Found
          </p>
        </div>
      )}

      {showModal && <ConfirmationModal
        confirmationText={confirmationText}
        onShow={handleAction}
        onClose={closeModalHandler}
        getConfirmation={handleConfirmation}
        btnText={modalAction}
      />}
      {isLoading && (
        <Loader></Loader>
      )}
    </div>
  );
};

export default Invitations;