import classes from "./ProgramsModalBody.module.css";
import universityLogo from "../../../../../../Assets/Logo/dougl-icon.svg";
import crossIcon from "../../../../../../Assets/Logo/white-cross-icon.svg";
import likeIcon from "../../../../../../Assets/Logo/like-heart-icon.svg";

function ProgramsModalBody({ onClose, program }) {

    return (
        <>
            <div className={classes["card-section"]}>

                <div className={classes["card-header"]}>
                    <div className={classes["section-1"]}>
                        <div className={classes["block-1"]}>
                            <div className={classes["university-icon"]}><img src={universityLogo} alt="Profile" /></div>
                            <div className={classes["university-name"]}>{program.university}</div>
                        </div>
                        <div className={classes["block-2"]}>
                            <div className={classes["program-name"]}>Accounting</div>
                            <div className={classes["program-detail-text"]}>
                                Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program.
                                Students will study a well-rounded curriculum that includes classes in accounting, communications, computing science, economics, finance and marketing...
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes["card-body"]}>
                    <div className={classes["section-2"]}>
                        <div className={classes["program-detail-section"]}>
                            <div>
                                <div className={classes["header"]}>Minimum Level of Education Completed</div>
                                <div className={classes["detail"]}>Grade 12 / High School</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Minimum GPA</div>
                                <div className={classes["detail"]}>60.0%</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Program Level</div>
                                <div className={classes["detail"]}>2 year Undergraduate Diploma</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Tuition</div>
                                <div className={classes["detail"]}>$13,980 / year</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Cost of Living</div>
                                <div className={classes["detail"]}>$20,635 / year</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Program Length</div>
                                <div className={classes["detail"]}>2 year College Diploma (Option for co-op)</div>
                            </div>
                        </div>
                        <div className={classes["program-detail-section"]}>
                            <div>
                                <div className={classes["header"]}>Application Fee</div>
                                <div className={classes["detail"]}>CAD $30</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>People like You</div>
                                <div className={classes["detail"]}>403 of 435 graduated</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                <div className={classes["detail"]}>95%</div>
                            </div>
                            <div>
                                <div className={classes["header"]}>Rank: #1</div>
                                <div className={classes["detail"]}>
                                    This program was ranked #1 for based on the these criteria:
                                    <ul>
                                        <li>Math Skills</li>
                                        <li>Analytical Mind</li>
                                        <li>Organized</li>
                                        <li>Logic and Reasoning</li>
                                        <li>Finances</li>
                                        <li>Critical Thinking</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes["cross-icon-div"]} onClick={onClose}><img className={classes["cross-icon"]} src={crossIcon} alt="Profile" /></div>
                <div className={classes["card-rank"]}><span className={classes["rank-text"]}>Ranked: #1</span></div>
                <div className={classes["heart-icon-div"]}><img className={classes["heart-icon"]} src={likeIcon} alt="like" /></div>
            </div>
        </>
    )
}

export default ProgramsModalBody;