import React from 'react'
import classes from "./PendingDocumentsSection.module.css";
import { Table } from 'react-bootstrap';
import deleteIcon from "../../../../../../Assets/Logo/documentDeleteICon.svg";
import documentView from "../../../../../../Assets/Logo/documetnView.svg";
import documentDownloadArrow from "../../../../../../Assets/Logo/documentDownloadArrow.svg";
const PendingDocumentsSection = () => {
    const tableData = {
        columns: ["Attachment", "Remark", "Date Uploaded", "UploadedBy"],
        documents: [
            {
                id: 1,
                "Attachment": 'Third Party Letter of Authorization',
                "Remark": '',
                'Date Uploaded': '29/08/2024',
                "UploadedBy": 'Jane Smith',
            },
            {
                id: 2,
                "Attachment": 'English Proficiency score card',
                "Remark": '',
                "Date Uploaded": '29/08/2024',
                "UploadedBy": 'Jane Smith',
            },
            {
                id: 3,
                "Attachment": 'Higher Secondary School Transcript',
                "Remark": '',
                "Date Uploaded": '29/08/2024',
                "UploadedBy": 'Jane Smith',
            },
            {
                id: 4,
                "Attachment": 'Passport Copy (Front & Last Page)',
                "Remark": '',
                "Date Uploaded": '29/08/2024',
                "UploadedBy": 'Jane Smith',
            },
            {
                id: 5,
                "Attachment": 'Secondary School Transcript',
                "Remark": '',
                "Date Uploaded": '29/08/2024',
                "UploadedBy": 'Jane Smith',
            },
        ]
      };
      const handleViewRequest = (id) => {
        console.log('id: ', id);
      };
  return (
    <div className={classes.container}>
      <Table className={classes.customTable}>    
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                </div>
              </th>
            ))}
            <th>View File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.documents.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
              <td>
                <span 
                  className={classes.actionBtn}
                  onClick={() => handleViewRequest(row["id"])}>
                <img src={documentView} className={classes.actionICon} alt="" />
                </span>
              </td>
              <td>
                <div className={`d-flex justify-content-center m-2`}>
                <div
                  className={classes.actionBtn}
                  onClick={() => handleViewRequest(row["id"])}>
                    <span className={` ${classes.centerIcon} ${classes.uploadBtn}`}>
                        <img src={documentDownloadArrow} className={classes.actionICon} alt="" />
                        <span className='ps-2'>Upload</span>
                    </span>
                </div>
                <div 
                  className={`${classes.actionBtn} ${classes.centerIcon}`}
                  onClick={() => handleViewRequest(row["id"])}>
                <img src={deleteIcon} className={classes.actionICon} alt="" />
                </div>
                  </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table> 
   </div>
  )
}

export default PendingDocumentsSection