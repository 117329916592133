 import React from "react"
 import './Loader.css'
import {HashLoader} from "react-spinners";
import { Spinner } from "react-bootstrap";
 function Loader(){
    return(
        <>
          <div className="spinner-container">
          {/* <GridLoader color="rgba(0, 90, 190, 1)" /> */}
          {/* <PropagateLoader color="rgba(0, 90, 190, 1)" /> */}
          {/* <HashLoader color="#213c92"/> */}
          <Spinner size="md" animation="border" />;
        </div>
        </>
    )
 }
 export default Loader;