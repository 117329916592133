import React from "react";
import classes from "./GroupsHeader.module.css";
import iconEdit from "../../../../../Assets/Logo/iconEdit.svg";
import iconOptions from "../../../../../Assets/Logo/iconOptions.svg";
import iconSearch from "../../../../../Assets/Logo/iconSearch.svg";
import iconShare from "../../../../../Assets/Logo/iconShare.svg";
import backgroundImage from "../../../../../Assets/Images/groups_main_background_image.png";

function GroupsHeader({image}) {
  return (
    <div className={classes["group_header_container"]}>
      <div className={classes["group_header"]}>
          <img
            src={backgroundImage}
            className={classes["background_image_pic"]}
            alt="Background"
          />
          <img
            src={iconEdit}
            className={`${classes["icon"]} ${classes["bottomLeft"]}`}
            alt="Edit Icon"
          />
          <img
            src={iconOptions}
            className={`${classes["icon"]} ${classes["topLeft"]}`}
            alt="Options Icon"
          />
          <img
            src={iconSearch}
            className={`${classes["icon"]} ${classes["topRight"]}`}
            alt="Search Icon"
          />
          <img
            src={iconShare}
            className={`${classes["icon"]} ${classes["bottomRight"]}`}
            alt="Share Icon"
          />
        </div>
    </div>
  );
}

export default GroupsHeader;
