import React from "react";
import classes from "./Profile.module.css";
import profileIcon from "../../../../../../Assets/Logo/profile-icon.svg";
const ProfileModel = () => {
  return (
    <div className={classes.container}>
      <div>
        <div className={classes.backgroundImgContainer}>
          <div></div>
          <div className={classes.backgroundImgMiniContainer}>
            <span>
              <img
                src={profileIcon}
                alt=""></img>
            </span>
            <div className={classes.textSizeAndColor}>Background</div>
          </div>
          <div></div>
        </div>
        <div className="mt-4">
          <div className={classes.profileImgContainer}>
            <div className={classes.profileImgDesign}>
              <div></div>
              <div className={classes.circleICon}>
                <span>
                  <img
                    src={profileIcon}
                    alt=""></img>
                </span>
                <div className={classes.textSizeAndColor}>Profile</div>
              </div>
              <div></div>
            </div>
          </div>
          <center>
            <div className={classes.extraTextSizeAndColor}>Upload your profile and background image</div>
          </center>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div></div>
        <div>
          <button className={classes.buttonSubmit +' '+ 'primary-button'}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default ProfileModel;
