import React, { useState } from "react";
import styles from './NewApplication.module.css';
import institutesImage from "../../../../../Assets/Images/institute-image.svg"
import ProgramSection from "./ProgramSection/ProgramSection";
import PendingDocumentsSection from "./PendingDocumentsSection/PendingDocumentsSection";
import AssignedCounsellor from "./AssignedCounsellor/AssignedCounsellor";
import ContactInfo from "./ContactInfo/ContactInfo";
import EducationSection from "./EducationSection/EducationSection";
const tabs=[{name:'Program Info', id:'Program'}, {name:'Documents', id:'Documents'},
    {name:'Personal Info', id:'Personal'},{name:'Contact Info', id:'Contact'}, {name:'Education Info', id:'Education'},
    {name:'Test Score', id:'Test'}, {name:'Assigned Counsellor', id:'Assigned'}
]

const NewApplication=()=>{
    const [programDetails, setProgramDetails]=useState('');

    const getScroll =(value)=>{
        document.getElementById(value).scrollIntoView({behavior:'smooth'});
    }

  const  getProgramDetails=(value)=>{
    setProgramDetails(value)

    }

   return(
       <>
        <div>
            <div>
                <div>
                    <p className={styles['heading']}>MSM Application</p>
                </div>

                <div className={styles['tabs-section']}>
                <div className={styles['tab-alignment']}>
                    {tabs.map(item=>(
                        <div onClick={()=>getScroll(item?.id)} className={styles['tab-text']}><p>{item?.name}</p></div>
                    ))}
                </div>

                </div>
                {!programDetails && (
                    <div className={styles['program-details']}>
                    <div className="row">
                        <div className={`${styles['col-white-background']} col-md-3 col-sm-12`}>
                            <img className={styles['institue-image']} src={institutesImage} alt=''></img>
                            
                        </div>
                        <div className={`${styles['col-background']} col-md-3 col-sm-12`}>
                            <p className={styles['sub-heading']}>Duration</p>
                            <p className={styles['sub-heading-result']}>4 years</p>
                            <p className={styles['sub-heading']}>Application Fee</p>
                            <p className={styles['sub-heading-result']}>CAD 80 one-time</p>

                        </div>
                        <div className={`${styles['col-background']} col-md-3 col-sm-12`}>
                            <p className={styles['sub-heading']}>Average Processing Time</p>
                            <p className={styles['sub-heading-result']}>4 weeks</p>
                            <p className={styles['sub-heading']}>LOA Deposit</p>
                            <p className={styles['sub-heading-result']}>CAD 2500 one-time</p>

                        </div>
                        <div className={`${styles['col-background']} col-md-3 col-sm-12`}>
                            <p className={styles['sub-heading']}>LOA Deposit</p>
                            <p className={styles['sub-heading-result']}>CAD 2500 one-time</p>
                            <p className={styles['sub-heading']}>Tuition Fee</p>
                            <p className={styles['sub-heading-result']}>CAD 19,579 per year</p>

                        </div>

                    </div>
                </div>
                )}
           
                <div id="Program" className="mb-3">
                    <div className={styles['header']}>
                        <p>Program Info</p>
                    </div>
                    <ProgramSection />
                </div>
                <div id="Documents" className="mb-3">
                    <div className={styles['header']}>
                        <p>Documents</p>
                    </div>
                    <PendingDocumentsSection/>
                </div>
                <div id="Personal" className="mb-3">
                <div className={styles['header']}>
                        <p>Personal Info</p>
                    </div>
                </div>
                <div id="Contact" className="mb-3">
                <div className={styles['header']}>
                        <p>Contact Info</p>
                    </div>
                    <div>
                        <ContactInfo></ContactInfo>
                    </div>
                </div>
                <div id="Education" className="mb-3">
                <div className={styles['header']}>
                        <p>Education Info</p>
                </div>
                <div>
                    <EducationSection></EducationSection>
                </div>
                </div>

                <div id="Test" className="mb-3">
                <div className={styles['header']}>
                        <p>Test Scores</p>
                    </div>
                </div>

                <div id="Assigned" className="mb-3">
                <div className={styles['header']}>
                        <p>Assigned Counsellor</p>
                </div>
                <AssignedCounsellor></AssignedCounsellor>
                </div>


            </div>
        
       </div>
       </>
   )
}
export default NewApplication;