import React from "react";
import classes from "./Section.module.css";
import { Link } from "react-router-dom";
import vectorLeftIcon from "../../../../../../Assets/Logo/Vector_left-icon.svg";
function DeactivateAccount({ onBack }) {
  return (
    <div className={`${classes["main-container"]}`}>
      <div className={`${classes["container-block"]} row`}>
        <div className={` ${classes["container-left-section"]} col-8 p-0`}>
          <div className="d-flex justify-content-between align-items-center">
            <div onClick={onBack} className={`${classes["prev-icon"]}`}>
              <img src={vectorLeftIcon}></img>
            </div>
          </div>
          <div className={classes["container-left-section-content"]}>
            <div>
              <div className={`pt-2 ${classes["changePasswordText"]}`}>
                <b>Account Management</b>
              </div>
              <div className={`pt-2`}>
                Would you like to deactivate you account?{" "}
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>
              <div></div>
            </div>
            <div className="form-group mb-2 pt-4 col-8">
              <label>
                <b>Type your email:</b>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Type email"
              />
            </div>
            <div className="form-group mb-2 col-8">
              <label>
                <b>Type your password:</b>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Type new Password..."
              />
            </div>
            <div className="form-group mb-2 col-8 ">
              <label>
                <b>Re-type your password:</b>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Re-Type new Password..."
              />
            </div>
          </div>
        </div>
        <div className={`${classes["container-right-section"]} col-4`}>
          <div className={`pt-4 mt-4 d-flex justify-content-center`}>
            <div>
              <button className={`${classes["btnColorCustom"]}`}>
                Deactivate
              </button>
              <div className="mt-2 text-center">
                <Link className={`${classes["removeLinkDecoration"]}`}>
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeactivateAccount;
