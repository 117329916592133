
import React from "react";
import classes from "./About.module.css"
import iconEdit2 from "../../../../../Assets/Logo/iconEdit2.svg";

function About({description}) {
  return (
    <div  className={classes["about-main-container"]}>
      <div  className={classes["about-content"]}>
        <div  className={classes["about-title"]}>About:</div>
        <img src={iconEdit2} alt="Icon"  />
      </div>
      <div className={classes["about-description"]}>
       {description}
      </div>
    </div>
  );
}

export default About;
