import React,{useState} from "react";
import classes from "./GroupsPage.module.css";
import GroupsDashboard from "./GroupsDashboard/GroupsDashboard"
import Sidebar from "./Sidebar/Sidebar";
import CreateGroup from "./CreateGroup/CreateGroup";

function GroupsPage() {

  const [currentShowComponent, setCurrentShowComponent] = useState('groupDashboard');

  const handleComponentToggle = (component) => {
    setCurrentShowComponent(component);
  };

  return (
    <div className={`${classes["main-groups-container"]}`}>
      <div className={`${classes[""]} row`}>

        <div className={`${classes["groups_section"]} col-sm-12 col-md-5 col-lg-3`}>
          <Sidebar toggleComponent={handleComponentToggle}/>
        </div>

        <div className={`${classes["groups_section"]} col-sm-12 col-md-7 col-lg-9`}>
        {currentShowComponent === 'createGroup' && <CreateGroup />}
        {currentShowComponent === 'groupDashboard' &&  <GroupsDashboard />}
        </div>
      </div>
    </div>
  );
}

export default GroupsPage;
