import classes from "./SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../../../Services/APIService.jsx";
import { useState } from "react";
import Loader from "../../../GlobleShared/Loader/Loader.jsx";
import { useBehaviorSubject } from "../../../GlobleShared/BehaviorSubject/BehaviorSubject.jsx";
import { toast } from "react-toastify";
import leftImg from "../../../../Assets/Images/signin-page-img.svg";
import union from "../../../../Assets/Logo/Union.svg";
import OTPInput from "react-otp-input";

const inputStyle = {
  width: "52px",
  height: "68px",
  margin: "5px 10px 5px 0px",
  fontSize: "3em",
  borderRadius: 12,
  border: "2px solid #213C92",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
};

const invalidOtpStyle = {
  width: "52px",
  height: "68px",
  margin: "5px 10px 5px 0px",
  fontSize: "3em",
  borderRadius: 12,
  border: "2px solid #E82529",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
  color: "#E82529",
};

function SignUp() {
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [currentStep, setCurrentStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(30);
  // State to hold password validation error
  const [passwordValidationErrors, setPasswordValidationErrors] = useState({});
  const [invalidOtp, setInvalidOtp] = useState();
  const [otpSubmitted, setOtpSubmitted] = useState(false);
  //  state for userDetails
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    cpassword: "",
  });

  // state for errors
  const [formErrors, setFormErrors] = useState({
    fname: null,
    lname: null,
    email: null,
    password: null,
    cpassword: null,
  });

  // Handler for input changes
  const changeHandler = (e) => {
    const { name, value } = e.target;
    // Update form state with the new value
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    // Validate the field and update formErrors state
    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(
        name,
        value,
        form[name === "password" ? "cpassword" : "password"]
      );
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }

    // Validate password when changing password or confirm password
    if (name === "password" || name === "cpassword") {
      const validationErrors = validatePassword(value);
      setPasswordValidationErrors(validationErrors);
    }
  };

  // Function to validate individual fields
  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "fname":
        if (!value) errorMsg = "Please enter First Name.";
        break;
      case "lname":
        if (!value) errorMsg = "Please enter Last Name.";
        break;
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter a valid Email.";
        break;
      case "password":
        if (!value) errorMsg = "Please enter Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password do not match.";
        break;
      case "cpassword":
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password do not match.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  // Function to validate password requirements {some optimization is remaining for this}
  const validatePassword = (password) => {
    const errors = {};
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;

    if (password.length < minLength) {
      errors.minLength = true;
    }
    if (!specialCharRegex.test(password)) {
      errors.specialChar = true;
    }
    if (!numberRegex.test(password)) {
      errors.number = true;
    }
    if (!uppercaseRegex.test(password)) {
      errors.uppercase = true;
    }

    return errors;
  };

  // Function to validate the entire form
  const validateForm = (form) => {
    const errorObj = {};
    Object.keys(form).forEach((key) => {
      const refValue =
        key === "password"
          ? form.cpassword
          : key === "cpassword"
          ? form.password
          : null;
      const msg = validateField(key, form[key], refValue);
      if (msg) errorObj[key] = msg;
    });
    return errorObj;
  };

  const handleSubmit = () => {
    console.log("handleupsubmit");
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      // setIsSubmit(true);
      const formData = {
        emailId: form.email,
        firstName: form.fname,
        lastName: form.lname,
        // mobCountryCode: null,
        contactNumber: null,
        password: form.password,
        // profilePic: "",
        userLoginType: 1
      };

      console.log("Data:", form);

      setisLoading(true);
      ApiService.userRegistration(formData)
        .then((res) => {
          setisLoading(false);
          toast.success(res?.message);
          navigate("/sign-in");
          console.log("hello");
        })
        .catch((error) => {
          setisLoading(false);
          toast.error(error?.message);
        });
    }
    console.log("Data:", form);
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const viewConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      if (form.email && form.password && form.cpassword) {
        setCurrentStep(2);
      }
    } else if (currentStep === 2) {
      if (form.fname && form.lname) {
        setCurrentStep(3);
      }
    }
    // just for checking next step
    else if (currentStep === 3) {
      if (form.email || phoneNumber) {
        countdown();
        setCurrentStep(4);
      }
    } else if (currentStep === 4 && otp?.length === 6) {
      otpSubmit();
    }
  };

  // all authorizaiton logic here

  // authorization logic to set bydefault the authorization is done by email
  const [authorizationMethod, setAuthorizationMethod] = useState("email");
  const [phoneNumber, setPhoneNumber] = useState("");

  // changing the authorization as per the check on email or phone
  const handleChange = (e) => {
    setAuthorizationMethod(e.target.value);
  };

  const otpSubmit = () => {
    if(otp === '123456'){
      setInvalidOtp(false)
      setOtpSubmitted(true);
      handleSubmit();
    }else{
      setInvalidOtp(true)  
    }
  };
  const getOtp = (otp) => {
    setInvalidOtp(false);
    console.log(otp);
    setOtp(otp);
  };
  const backOnAuthentication = () => {
    setTimeLeft(0);
    setCurrentStep(currentStep - 1);
    setInvalidOtp(false);
    setOtp("");
  };

  const resendOtp = () => {
    countdown();
    setOtp("");
    setInvalidOtp(false);
  };
  const countdown = () => {
    let intervalId;
    clearInterval(intervalId);
    setTimeLeft(30);
    intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  return (
    <div className={classes["main-container"]}>
      <div className={`${classes.row_hieght} row`}>
        <div className={`${classes.col_img} col-md-7`}>
          <div>
            <img className={classes["left-img"]} src={leftImg} alt="" />
          </div>
        </div>
        <div className={`${classes.right_col} col-md-5`}>
          <div className={classes["sign_up-box"]}>
            <div className={classes.header}>
              <span>
                <img src={union} alt="" />
              </span>
              <span>MSM Pursuit</span>
            </div>

            <form>
              <div className={classes["form-section"]}>
                {/* step1 */}

                {currentStep === 1 && (
                  <div className={classes["form-content"]}>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className={`${classes.label} form-label`}
                      >
                        Email
                      </label>
                      <input
                        onChange={changeHandler}
                        type="text"
                        placeholder="Type Email Here..."
                        name="email"
                        value={form.email}
                        onBlur={changeHandler}
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      {formErrors.email && (
                        <span className={classes.error}>
                          {formErrors.email}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputPassword1"
                        className={`${classes.label} form-label`}
                      >
                        Password
                      </label>

                      <div className={classes["input-with-icon"]}>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          type={passwordType}
                          placeholder="Type Password Here..."
                          name="password"
                          value={form.password}
                          className="form-control "
                          id="exampleInputPassword1"
                        />
                        {form.password && (
                          <div className={classes["icon-container"]}>
                            <span
                              className={classes["eyeicon"]}
                              onClick={viewPass}
                            >
                              <i
                                className={`fa ${
                                  visible ? "fa-eye" : "fa-eye-slash"
                                }`}
                              />
                            </span>
                          </div>
                        )}
                      </div>

                      {formErrors.password && (
                        <span className={classes.error}>
                          {formErrors.password}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputConfirmPassword"
                        className={`${classes.label} form-label`}
                      >
                        Confirm Password
                      </label>
                      <div className={classes["input-with-icon"]}>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          type={confirmPasswordType}
                          placeholder="Type Password Here..."
                          name="cpassword"
                          value={form.cpassword}
                          className="form-control"
                          id="exampleInputConfirmPassword"
                        />
                        {form.cpassword && (
                          <div className={classes["icon-container"]}>
                            <span
                              className={classes["eyeicon"]}
                              onClick={viewConfirmPass}
                            >
                              <i
                                className={`fa ${
                                  visibleConfirm ? "fa-eye" : "fa-eye-slash"
                                }`}
                              />
                            </span>
                          </div>
                        )}
                      </div>

                      {formErrors.cpassword && (
                        <span className={classes.error}>
                          {formErrors.cpassword}
                        </span>
                      )}
                    </div>

                    <div className={classes["password-validation"]}>
                      <p className={classes["validation-heading"]}>
                        Password must contain:
                      </p>

                      <ul className={classes["validation-item"]}>
                        {!form.password ? (
                          <li>
                            <span>At least 8 characters</span>
                          </li>
                        ) : !passwordValidationErrors.minLength ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 8 characters
                              </span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 8 characters
                              </span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}

                        {!form.password ? (
                          <li>
                            <span>At least 1 special character</span>
                          </li>
                        ) : !passwordValidationErrors.specialChar ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 special character
                              </span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 special character
                              </span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}

                        {!form.password ? (
                          <li>
                            <span>At least 1 number</span>
                          </li>
                        ) : !passwordValidationErrors.number ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">At least 1 number</span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">At least 1 number</span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}

                        {!form.password ? (
                          <li>
                            <span>At least 1 uppercase letter</span>
                          </li>
                        ) : !passwordValidationErrors.uppercase ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 uppercase letter
                              </span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 uppercase letter
                              </span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}

                {/* step 2*/}

                {currentStep === 2 && (
                  <div className={classes["form-content"]}>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputFirstName"
                        className={`${classes.label} form-label`}
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        placeholder="Type Name Here..."
                        name="fname"
                        value={form.fname}
                        onChange={changeHandler}
                        onBlur={changeHandler}
                        className="form-control"
                        id="exampleInputFName"
                      />
                      {formErrors.fname && (
                        <span className={classes.error}>
                          {formErrors.fname}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputLastName"
                        className={`${classes.label} form-label`}
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        placeholder="Type Name Here..."
                        name="lname"
                        value={form.lname}
                        onChange={changeHandler}
                        onBlur={changeHandler}
                        className="form-control"
                        id="exampleInputLName"
                      />
                      {formErrors.lname && (
                        <span className={classes.error}>
                          {formErrors.lname}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/* step 3 */}

                {currentStep === 3 && (
                  <>
                    <div className={classes["authorization-container"]}>
                      <div>
                        <p className={classes["heading"]}>
                          Account Authorization:
                        </p>
                      </div>
                      <div className={classes["authorization-content"]}>
                        <div className={classes["authorization-option"]}>
                          <input
                            className={`${classes.radioInput}`}
                            type="radio"
                            id="email"
                            name="authorization"
                            value="email"
                            checked={authorizationMethod === "email"}
                            onChange={handleChange}
                          />
                          <label className="form-check-label" htmlFor="email">
                            <span>Email : </span>
                            <span>{form.email}</span>
                          </label>
                        </div>
                        <div className={classes["authorization-option"]}>
                          <input
                            className={`${classes.radioInput}`}
                            type="radio"
                            id="phone"
                            name="authorization"
                            value="phone"
                            checked={authorizationMethod === "phone"}
                            onChange={handleChange}
                          />
                          <label
                            className={`${classes.phone_section} form-group form-check-label `}
                            htmlFor="phone"
                          >
                            <span>Phone : </span>
                            <span className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Type phone number here..."
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              disabled={authorizationMethod !== "phone"}
                            />
                            </span>
                       
                          </label>
                        </div>
                      </div>
                      {(form.email || phoneNumber) && (
                        <p className={classes["verify-message"]}>
                          We will send you a code to verify your account and to
                          keep it secure.
                        </p>
                      )}
                    </div>
                  </>
                )}

                {currentStep === 4 && (
                  <>
                    <div className={classes["otp_section"]}>
                      <div>
                        <p className={classes["heading"]}>
                          Account Authorization:
                        </p>
                      </div>
                      <div className={classes["otp_input"]}>
                        <OTPInput
                          inputStyle={invalidOtp ? invalidOtpStyle : inputStyle}
                          value={otp}
                          onChange={getOtp}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => (
                            <input
                              className={classes["otp_Input"]}
                              {...props}
                            />
                          )}
                        />
                      </div>
                      <div>
                        {invalidOtp && (
                          <p className={classes["timer"]}>
                            Wrong code. Please try again
                          </p>
                        )}
                        {otpSubmitted && (
                          <p className={classes["timer"]}>Success!</p>
                        )}
                      </div>
                      <div>
                        <p className={classes["timer"]}>{timeLeft} seconds</p>
                      </div>
                      <div>
                        <button
                          onClick={resendOtp}
                          type="button"
                          disabled={timeLeft !== 0}
                          className={`${classes.primary_button} primary-button`}
                        >
                          Resend Code
                        </button>
                      </div>
                      <div>
                        <p
                          onClick={backOnAuthentication}
                          className={classes["change_auth"]}
                        >
                          Change Authentication Device
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-2">
                  <button
                    disabled={
                      (currentStep === 1 &&
                        (!form.email || !form.password || !form.cpassword)) ||
                      (currentStep === 2 && (!form.fname || !form.lname)) ||
                      (currentStep === 4 && otp?.length < 6) || Object.keys(formErrors).some(
                        (key) => formErrors[key])
                    }
                    className={`${classes.primary_button} primary-button`}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  );
}

export default SignUp;
