import { Form } from "react-bootstrap";
import classes from "./ProgramSection.module.css";

function ProgramSection() {
    return (
        <>
            <div className={classes["program-info-container"]}>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Institute</Form.Label>
                            <Form.Select aria-label="Institute">
                                <option></option>
                                <option value="1">Cape Breton University</option>
                                <option value="2">Cape Breton University</option>
                                <option value="3">Cape Breton University</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Program</Form.Label>
                            <Form.Select aria-label="Program">
                                <option></option>
                                <option value="1">Cape Breton University</option>
                                <option value="2">Cape Breton University</option>
                                <option value="3">Cape Breton University</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label>Intake</Form.Label>
                            <Form.Select aria-label="Intake">
                                <option></option>
                                <option value="1">Cape Breton University</option>
                                <option value="2">Cape Breton University</option>
                                <option value="3">Cape Breton University</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                            <Form.Label>Campus</Form.Label>
                            <Form.Select aria-label="Campus">
                                <option></option>
                                <option value="1">Cape Breton University</option>
                                <option value="2">Cape Breton University</option>
                                <option value="3">Cape Breton University</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                            <Form.Label>Residing</Form.Label>
                            <Form.Select aria-label="Residing">
                                <option></option>
                                <option value="1">Cape Breton University</option>
                                <option value="2">Cape Breton University</option>
                                <option value="3">Cape Breton University</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProgramSection;