import classes from './Programs.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { Form } from "react-bootstrap";
import universityLogo from "../../../../../Assets/Logo/dougl-icon.svg";
import { useState } from 'react';
import SearchModal from '../SearchModal/SearchModal';
import ProgramsModalBody from './ProgramsModalBody/ProgramsModalBody';

const Programs = () => {
    const [modalIsVisible, setModalIsVisible] = useState();
    const [programData, setprogramData] = useState({});
    const [modalSize, setModalSize] = useState('');

    function hideModalhandler() {
        setModalIsVisible(false);
    }

    function showModalhandler(value, size) {
        setModalIsVisible(true);
        setprogramData(value);
        setModalSize(size);
    }

    const programs = [
        {
            id: 1,
            university: "Douglas College"
        },
        {
            id: 2,
            university: "BCIT"
        },
        {
            id: 3,
            university: "Alexander College"
        },
        {
            id: 4,
            university: "Simon Frasier"
        },
        {
            id: 5,
            university: "Douglas College"
        },
        {
            id: 6,
            university: "Douglas College"
        },
    ];

    return (
        <>
            {modalIsVisible && (
                <SearchModal onShow={showModalhandler} size={modalSize}>
                    <ProgramsModalBody onClose={hideModalhandler} program={programData} />
                </SearchModal>
            )}

            <div>
                <div className="row">

                    {/* Secondary Filters */}
                    <div className="col-md-2">
                        <div>
                            <Accordion className="filter-section" defaultActiveKey={['0', '1', '2']} alwaysOpen>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Course Type</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Check
                                                type="checkbox"
                                                id="ct-onCampus"
                                                label="On-Campus"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="ct-hybrid"
                                                label="Hybrid"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="ct-remote"
                                                label="Remote"
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Tuition</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Check
                                                type="checkbox"
                                                id="tutionFee1"
                                                label="$1000 - $5000"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="tutionFee2"
                                                label="$5001 - $10,000"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="tutionFee3"
                                                label="$10,001 - $15,000"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="tutionFee4"
                                                label="$15,001 - $20,000"
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Duration</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Check
                                                type="checkbox"
                                                id="duration-6Month"
                                                label="6 month"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="duration-1year"
                                                label="1 year"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="duration-2year"
                                                label="2 year"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="duration-3year"
                                                label="3 year"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="duration-4year"
                                                label="4 year"
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="col-md-10">
                        <div className={classes["program-cards"]}>
                            {programs.map((program) => (
                                <div className={classes["card-section"]} onClick={() => showModalhandler(program, 'md')} key={program.id}>
                                    <div className={classes["section-1"]}>
                                        <div className={classes["card-header-block-1"]}>
                                            <div className={classes["card-rank"]}> <span className={classes["rank-text"]}>Ranked: #1</span></div>
                                            <div className={classes["card-header"]}>
                                                <div className={classes["university-icon"]}><img src={universityLogo} alt="Profile" /></div>
                                                <div className={classes["university-detail"]}>
                                                    <div className={classes["university-name"]}>{program.university}</div>
                                                    <div className={classes["university-address"]}>New Westminster,</div>
                                                    <div className={classes["university-address"]}>British Columbia, Canada</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes["card-header-block-2"]}>
                                            <div className={classes["program-name"]}>Accounting</div>
                                            <div className={classes["program-detail-text"]}>
                                                Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program.
                                                Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes["section-2"]}>
                                        <div className={classes["program-detail-section-1"]}>
                                            <div>
                                                <div className={classes["intake-header"]}>Intake</div>
                                                <div className={classes["detail"]}>Sep 2024</div>
                                                <div className={classes["detail"]}>Sep 2024</div>
                                            </div>
                                            <div className={classes["detail"]}>2 Years</div>
                                            <div className={classes["detail"]}>Bachelors Degree</div>
                                            <div className={classes["detail"]}>CAD $14,110 / Year</div>
                                        </div>
                                        <div className={classes["program-detail-section-2"]}>
                                            <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail"]}>CAD $30</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>People like You</div>
                                                <div className={classes["detail"]}>403 of 435 graduated</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                                <div className={classes["detail"]}>95%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Programs;