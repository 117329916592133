import React, { useEffect, useRef, useState } from "react";
import classes from "./SubConnection.module.css";
import ConnectionProfileCard from "./ConnectionProfileCard";
import ChatWindow from "../../../../GlobleShared/Chat/ChatWindow";
import dropdown from "../../../../../Assets/Logo/drop-down-icon-2.svg";
import ConnectionService from "../../../../../Services/ConnectionService";
import Loader from "../../../../GlobleShared/Loader/Loader";
const SubConnections = () => {
  const [profiles, setProfiles] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const [chatWindows, setChatWindows] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLsLoading] = useState(false);
  const initialized = useRef(false)

  const handleOpenChat = (profile) => {
    setChatWindows((prevChats) => {
      if (!prevChats.some((chat) => chat.userConnectionId === profile.userConnectionId)) {
        return [...prevChats, { ...profile, isMinimized: false }];
      }
      return prevChats;
    });
  };
  const handleCloseChat = (id) => {
    setChatWindows((prevChats) => prevChats.filter((chat) => chat.userConnectionId !== id));
  };

  const handleMinimizeChat = (id) => {
    setChatWindows((prevChats) => prevChats.map((chat) => (chat.userConnectionId === id ? { ...chat, isMinimized: !chat.isMinimized } : chat)));
  };

  const handleTogglePopup = (id) => {
    setActivePopup((prevId) => (prevId === id ? null : id));
  };

  const handleHidePopup = () => {
    refreshConnection(page,limit)
    
  };
  const hidePopup = () => {
    setActivePopup(null);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getConnection(page);
    }
  }, []);

  const getConnection=(pageValue)=>{
    let data={
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: ""
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...profiles,...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }

  const refreshConnection=(pageNum,limit)=>{
    let data={
      limit: pageNum*limit,
      page: 1,
      paginated: paginated,
      searchTerm: ""
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }

  const getdataReload=()=>{
    //getConnection(page);
    refreshConnection(page,limit)
  }
  const viewMore=()=>{
    getConnection(page+1)
    setPage(page+1)
  }
  return (
    <div className={classes.commonSection}>
      <div className={classes.container}>
        <div className={classes.displayMange}>
          <div className={classes.firstDisplay}>{totalCount} Connections</div>
          <div className={classes.secondDisplay}><span className={classes.sortByText}>Sort by:</span> <span className={classes.recentlyAdded}>Recently added </span><img src={dropdown} alt=""/></div>
        </div>
        {profiles.length > 0 && (
            <div>
            {profiles.map((profile, index) => (
            <ConnectionProfileCard
              key={profile?.userConnectionId}
              connectionId={profile?.userConnectionId}
              backgroundImage={profile?.profileBGImage}
              imgSrc={profile?.profileImage}
              name={profile?.displayName}
              description={profile?.aboutUs}
              connections={profile?.connections}
              isPopupVisible={activePopup === profile?.userConnectionId}
              onTogglePopup={() => handleTogglePopup(profile?.userConnectionId)}
              onHidePopup={handleHidePopup}
              onOpenChat={() => handleOpenChat(profile)}
              disconnectIsTrue={false}
              index={index}
              totalCards={profiles?.length}
              getConfirmationValue={getdataReload}
              hidePopupBox={hidePopup}
              />
          ))}
          {(totalCount!==profiles?.length && profiles.length>0) && (
         <div className="text-center mb-2">
         <button onClick={viewMore} className={classes['view-more']}>View more</button>
          </div>
          )}

        </div>
      )}
      {profiles.length===0 && (
        <div className="text-center">
          <p>
            No Connection Found
          </p>
          </div>
      )}
     
        <div className={classes.chatContainer}>
          <div className={`${classes.forChatScreen} row`}>
            <div className="col-md-3"></div>
            {chatWindows.slice(0, 2).map((chat, index) => (
              <div
                key={chat?.userConnectionId}
                className={`col-md-4`}>
                <ChatWindow
                  id={chat?.userConnectionId}
                  name={chat?.displayName}
                  profile={chat?.profileImage}
                  onClose={() => handleCloseChat(chat?.userConnectionId)}
                  onMinimize={() => handleMinimizeChat(chat?.userConnectionId)}
                  isMinimized={chat.isMinimized}
                />
              </div>
            ))}
            <div className="col-md-1">
              {chatWindows.map((chat) => (
                <div key={chat?.userConnectionId}>
                  <img
                    className={classes.profilePicBottom}
                    src={chat?.profileImage}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <Loader></Loader>
      )}
    </div>
    
  );
};

export default SubConnections;
