import React from "react";
import classes from "./InstitutesCard.module.css";
import universityLogo from "../../../Assets/Logo/dougl-icon.svg";

const InstitutesCard = ({ institute }) => {
  return (
    <>
      <div className={classes["card-section"]}>
        <div className={classes["section-1"]}>
          <div className={classes["card-header-block-1"]} style={{ backgroundColor: institute.backgroundColor }}>
            <div className={classes["card-rank"]}>
              {" "}
              <span className={classes["rank-text"]}>Ranked: #{institute.ranked}</span>
            </div>
            <div className={classes["card-header"]}>
              <div className={classes["university-icon"]}>
                <img
                  src={institute.img}
                  alt="Profile"
                />
              </div>
              <div className={classes["university-detail"]}>
                <div className={classes["university-name"]}>{institute.name}</div>
                <div className={classes["university-address"]}>{institute.landmark}</div>
                <div className={classes["university-address"]}>{institute.location}</div>
              </div>
            </div>
          </div>
          <div className={classes["card-header-block-2"]}>
            <div className={classes["program-name"]}>{institute.account}</div>
            <div className={classes["program-detail-text"]}> {institute.description} </div>
          </div>
        </div>
        <div className={classes["section-2"]}>
          <div className={classes["program-detail-section-1"]}>
            <div>
              <div className={classes["intake-header"]}>Intake</div>
              <div className={classes["detail"]}>{institute.intake.startDate}</div>
              <div className={classes["detail"]}>{institute.intake.endDate}</div>
            </div>
            <div className={classes["detail"]}>{institute.intake.duration}</div>
            <div className={classes["detail"]}>{institute.degree}</div>
            <div className={classes["detail"]}>{institute.degreeFee}</div>
          </div>
          <div className={classes["program-detail-section-2"]}>
            <div>
              <div className={classes["header"]}>Application Fee</div>
              <div className={classes["detail"]}>{institute.applicationFee}</div>
            </div>
            <div>
              <div className={classes["header"]}>People like You </div>
              <div className={classes["detail"]}>{institute.graduates}</div>
            </div>
            <div>
              <div className={classes["header"]}>Likelihood of Graduation</div>
              <div className={classes["detail"]}>{institute.graduationLikelihood}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstitutesCard;
