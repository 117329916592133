import React from "react";
import { Route } from 'react-router-dom';
import AuthGuard from "../../GlobleShared/Guards/AuthGuard";
import Dashboard from "../Home/Dashboard/Dashboard";
import UserAccount from "../Home/User_Account/User_Account";
import DashboardPage from "../Home/Dashboard/DashboardPage/DashboardPage";
import ConnectionsComponents from "../Home/Connections/ConnectionsComponents";
import GroupsPage from "../Home/Groups/GroupsPage";
import ProgramDetails from "../Home/ProgramDetails/ProgramDetails";
import InstituteDetails from "../Home/InstituteDetails/InstituteDetails";
import SearchPage from "../Home/Search/SearchPage/SerachPage";
import CurrencyConverter from "../Home/Tools/CurrencyConverter/CurrencyConverter";
import LivingCostCalculator from "../Home/Tools/LivingCostCalculator/LivingCostCalculator";
import EducationCalculator from "../Home/Tools/EducationCalculator/EducationCalculator";
import EnglishScoreCalculator from "../Home/Tools/EnglishScoreCalculator/EnglishScoreCalculator";
import ApplicationList from "../Home/Applications/ApplicationList/ApplicationList";
import NewApplication from "../Home/Applications/NewApplication/NewApplication";
import ApplicationDetails from "../Home/Applications/ViewApplicationDetails/ApplicationDetails";
import Applications from "../Home/Applications/Applications/Applications";
import DeferralRequests from "../Home/Applications/DeferralRequest/DeferralRequest";
import RetriveDeleteApplication from "../Home/Applications/RetriveDeleteApplications/RetriveDeleteApplications";
import Refunds from "../Home/Applications/Refunds/Refunds";
import ToolsComponent from "../Home/Tools/ToolsComponent/ToolsComponent";
import ProfileSection from "../../Common/ProfileSection/ProfileSection"

const AuthRoutes = [
      <Route  path="/member" element={<AuthGuard component={<Dashboard />} />}>
      <Route  path="dashboard" element={<DashboardPage />} />
      <Route  path="profile" element={<UserAccount />} />
      <Route  path="connections" element={<ConnectionsComponents />} />
      <Route  path="groups" element={<GroupsPage />} />
      <Route  path="program-details" element={<ProgramDetails/>} />
      <Route  path="profileView" element={<ProfileSection />} />

      <Route  path="institute-details" element={<InstituteDetails/>} />
    
      <Route path="search" element={<SearchPage />} />

      <Route path="application"  element={<AuthGuard component={<Applications />} />}> 
            <Route path="list" element={<ApplicationList/>}></Route>
            <Route path="add/:categoryId" element={<NewApplication/>}></Route>
            <Route path="details/:applicationId" element={<ApplicationDetails/>}></Route>
            <Route path="refund" element={<Refunds />}></Route>
            <Route path="retrive-application" element={<RetriveDeleteApplication/>}></Route>
            <Route path="deferral-requests" element={<DeferralRequests/>}></Route>
      </Route>

      <Route path="tools"  element={<AuthGuard component={<ToolsComponent />} />}> 
          <Route path="education-calculator" element={<EducationCalculator/>}></Route>
          <Route path="living-cost-calculator" element={<LivingCostCalculator/>}></Route>
          <Route path="currency-converter" element={<CurrencyConverter/>}></Route>
          <Route path="english-score-calculator" element={<EnglishScoreCalculator/>}></Route>
      </Route>

    </Route>

]

export default AuthRoutes;