import React, { useState } from "react";
import classes from "./ProfileSection.module.css";
import avatarImage from "../../../Assets/Images/backgroung_profile_image.jpg";
import profile_picture from "../../../Assets/Images/profile_picture.png";
import edit_profile from "../../../Assets/Logo/edit_profile.svg";
import About from "./About/About";

function ProfileSection() {
  const [tab, setTab] = useState("");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [selectedHeading, setselectedHeading] = useState("");

  function hideModalhandler() {
    setModalIsVisible(false);
  }

  function showModalhandler(header) {
    setselectedHeading(header);
    setModalIsVisible(true);
  }

  return (
    <div className={`${classes["user_account"]}`}>
      <div className={` ${classes["user_account_section"]}`}>
        <div className={classes["user_account_info"]}>
          <div className={classes["background_image"]}>
            <div className={classes["background_image_pic"]}>
              <img src={avatarImage} alt="background-image " />
            </div>
            <div className={classes["profile_pic"]}>
              <span className={classes["user_profile_pic"]}>
                <img src={profile_picture} alt="User Profile_Picture" />
              </span>
            </div>
          </div>
          <div className={`row ${classes["user_details"]}`}>
            <div className={`col-md-6 ${classes["user_personalDetails"]}`}>
              <p>
                Jane Smith <span>(She/Her)</span>
              </p>
              <p>fakeEmail@email.com</p>
            </div>

            <div className={`col-md-6 ${classes["user_highlights"]}`}>
              <p>Studying Computer Science</p>
              <p>Highlights</p>
            </div>
          </div>

          <div className={classes["user_connections"]}>172 Connections</div>

          <div className={classes["section-line"]}>
            <hr />
          </div>

         <div className={classes["user_navigation-section"]}>
          <div className={classes["user_navigation"]}>
            <button
              className={tab === "About" ? classes["active_button"] : ""}
              onClick={() => setTab("About")}
            >
              About
            </button>
          </div>
          <div>
            <button className={classes["send-req-btn"]}>Send Request</button>
          </div>
          </div>
        </div>
        {tab === "About" && (
          <div>
            <About />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileSection;
