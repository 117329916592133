import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import searchIcon from "../../.../../../../../Assets/Logo/search-icon-2.svg";
import groups_image from "../../.../../../../../Assets/Images/groups_image.png";
import GroupProfiles from "./GroupProfiles";

const Sidebar = ({toggleComponent}) => {
  const [tab, setTab] = useState("your-group");
  const [groups, setGroups] = useState([
    {
      id: 1,
      name: "Study Group",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "",
      tabType: "your-group",
      member: 250,
      crown:true
    },
    {
      id: 2,
      name: "Engineer's Advice",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "",
      tabType: "your-group",
      member: 310,
      crown:false
    },
    {
      id: 3,
      name: "Family Chat",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "",
      tabType: "your-group",
      crown:false,
      member: 700
    },
    {
      id: 4,
      name: "Designers Discuss",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "",
      crown:false,
      tabType: "your-group",
      member: 500
    },
    {
      id: 5,
      name: "The New New",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "",
      tabType: "your-group",
      member: 275,
      crown:true
    },
    {
      id: 6,
      name: "Travellers",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "Public Group",
      crown:false,
      tabType: "suggestions",
      member: 450
    },
    {
      id: 7,
      name: "Tech Lovers",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "Private Group",
      crown:false,
      tabType: "suggestions",
      member: 390
    },
    {
      id: 8,
      name: "Family Chat",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "Public Group",
      crown:false,
      tabType: "suggestions",
      member: 420
    },
    {
      id: 9,
      name: "New Tech",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "Private Group",
      crown:false,
      tabType: "invitations",
      member: 600
    },
    {
      id: 10,
      name: "Film Chat",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "Private Group",
      crown:false,
      tabType: "invitations",
      member: 340
    },
    {
      id: 11,
      name: "Comics Lovers",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      crown:false,
      groupType: "Private Group",
      tabType: "invitations",
      member: 390
    },
    {
      id: 12,
      name: "Coding Hints",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      crown:false,
      groupType: "Private Group",
      tabType: "requests",
      member: 480
    },
    {
      id: 13,
      name: "Backend Brunch Meetup",
      image: "https://via.placeholder.com/50",
      update: "5 mins ago",
      groupType: "Private Group",
      crown:false,
      tabType: "requests",
      member: 550
    }
    
  ]);
  const filteredGroups = groups.filter((group) => group.tabType === tab);
  return (
    <div className={classes["container"]}>
      <div className={classes["section-1"]}>
        <div>
          <span className={classes["beta-section-1"]}>Groups</span>
        </div>
        <div className={classes["beta-section-2"]}>
          <button onClick={() =>{setTab(""); toggleComponent('createGroup');}} className={classes["section-button"]}>Create Group</button>
        </div>
      </div>
      <div className={classes["section-2"]}>
        <div className={classes["search-bar"]}>
          <input
            type="text"
            placeholder="Search Groups"
          />
          <button type="submit">
            <img
              src={searchIcon}
              alt="Search"
            />
          </button>
        </div>
      </div>
      <div className={classes["section-3"]}>
        <div className="row">
          <div className={`${classes["buttonRow"]} col-md-12 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "your-group" ? classes["activeButton"] : ""}`}
              onClick={() =>{ setTab("your-group"); toggleComponent('groupDashboard');}}>
              Your Groups
            </button>
          </div>
          <div className={`${classes["buttonRow"]} col-md-12 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "suggestions" ? classes["activeButton"] : ""}`}
              onClick={() => setTab("suggestions")}>
              Suggestions
            </button>
          </div>
          <div className={`${classes["buttonRow"]} col-md-12 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "invitations" ? classes["activeButton"] : ""}`}
              onClick={() => setTab("invitations")}>
              Invitations
            </button>
          </div>
          <div className={`${classes["buttonRow"]} col-md-12 col-lg-6`}>
            <button
              className={`${classes["buttonCustom"]} ${tab === "requests" ? classes["activeButton"] : ""}`}
              onClick={() => setTab("requests")}>
              Requests
            </button>
          </div>
        </div>
      </div>
      <div className={classes["section-4"]}>
        {filteredGroups.map((group, index) => (
          <GroupProfiles
            key={group.id}
            index={index}
            groupLength={filteredGroups.length}
            id={group.id}
            name={group.name}
            update={group.update}
            groupType={group.groupType}
            member={group.member}
            image={groups_image}
            tab={tab}
            crown={group.crown}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
