import React, { useRef, useState } from "react";
import AIME from "../AIME/AIME";
import Programs from "../Programs/Programs";
import Institutes from "../Institutes/Institutes";
import Scholarship from "../Scholarship/ScholarShip";
import styles from './SearchPage.module.css';
import allfilterIcon from '../../../../../Assets/Logo/all-filter.svg'
import alphaBetIcon from '../../../../../Assets/Logo/alphabet-icon.svg'
import dropdownIcon from '../../../../../Assets/Logo/drop-down_icon.svg'
import searchIcon from '../../../../../Assets/Logo/searchIcon.svg'
import updownIcon from '../../../../../Assets/Logo/updownarrow.svg'
import whiteArrow from '../../../../../Assets/Logo/white-down-arrow.svg';
import locattionIcon from '../../../../../Assets/ALL_FIlter/blue-location-icon.svg'
import tutionIcon from '../../../../../Assets/ALL_FIlter/tution-icon.svg'
import courseIcon from '../../../../../Assets/ALL_FIlter/course-type-icon.svg'
import applicationIcon from '../../../../../Assets/ALL_FIlter/application-icon.svg'
import englishIcon from '../../../../../Assets/ALL_FIlter/engilsh-score-icon.svg'
import intakeIcon from '../../../../../Assets/ALL_FIlter/intake-icon.svg'
import schoolIcon from '../../../../../Assets/ALL_FIlter/school-icon.svg'
import gmtIcon from '../../../../../Assets/ALL_FIlter/gmt-icon.svg'
import internshipIcon from '../../../../../Assets/ALL_FIlter/internship-icon.svg'
import desiplineIcon from '../../../../../Assets/ALL_FIlter/decipline-icon.svg'
import durationIcon from '../../../../../Assets/ALL_FIlter/duration-icon.svg'
import SearchModal from "../SearchModal/SearchModal";
import ComparePrograms from "../../../../Common/ComparePrograms/ComparePrograms";

const sortAlphabet=['Alphabetical', 'Tution', 'Ranking', 'Likes', 'Institute', 'Program'];
const country=['India', 'Canada', 'Nepal', 'US', 'UK']

const SearchPage= ()=>{
    const shortDiv = useRef(null);
     const countryDiv = useRef(null);
     const inputdiv=useRef(null);
     const allfilterDiv=useRef(null);
    const [tabIndex, setTabIndex]=useState(1);
    const [shordropdown, setShordropdown]= useState(false);
    const [countryFlag, setcountryFlag]= useState(false);
    const [countryName, setCountryName]= useState('Country');
    const [query, setQuery] = useState('');
    const [inputFlag, setInputFlag]= useState(false);
    const [allFilterFlag, setAllFilterFlag]= useState(false);
    const [locationFlag, setlocationFlag]= useState(false);
    const [courseTypeFlag, setcourseTypeFlag]= useState(false);
    const [tutionFlag, settutionFlag]= useState(false);
    const [durationFlag, setdurationFlag]= useState(false);
    const [internshipFlag, setinternshipFlag]= useState(false);
    const [intakeFlag, setintakeFlag]= useState(false);
    const [applicatioFlag, setapplicatioFlag]= useState(false);
    const [greFlag, setgreFlag]= useState(false);
    const [schoolFlag, setschoolFlag]= useState(false);
    const [desipilineFlag, setdesipilineFlag]= useState(false);
    const [englishScorFlag, setenglishScorFlag]= useState(false);
    const [modalIsVisible, setModalIsVisible] = useState();
    const [modalSize, setModalSize] = useState('');
  
    const [items, setItems] = useState([
        'Apple',
        'Banana',
        'Cherry',
        'Date',
        'Fig',
        'Grape',
        'Kiwi'
      ]);

    const getTabIndex=(index)=>{
        setTabIndex(index);
        setShordropdown(false) 

    }
    const showSortDrop=()=>{
        if(!shordropdown){
            setShordropdown(true)
        }
        else if(shordropdown){
            setShordropdown(false) 
        }
        
    }
    const showCountry=(countryName)=>{
        if(countryName){
            setCountryName(countryName)
        }
        
        if(!countryFlag){
            setcountryFlag(true)
        }
        else if(countryFlag){
            setcountryFlag(false) 
        }
    }
    const showLocationFilter=()=>{
        if(!locationFlag){
            setlocationFlag(true)
        }else if(locationFlag){
            setlocationFlag(false)
        }
        
    }
    const showCourseType=()=>{
        if(!courseTypeFlag){
            setcourseTypeFlag(true)
        }else if(courseTypeFlag){
            setcourseTypeFlag(false)
        }
        
    }
    const showtution=()=>{
        if(!tutionFlag){
            settutionFlag(true)
        }else if(tutionFlag){
            settutionFlag(false)
        }
        
    }
    const showduration=()=>{
        if(!durationFlag){
            setdurationFlag(true)
        }else if(durationFlag){
            setdurationFlag(false)
        }
        
    }
    const showinternship=()=>{
        if(!internshipFlag){
            setinternshipFlag(true)
        }else if(internshipFlag){
            setinternshipFlag(false)
        }
        
    }
    const showintake=()=>{
        if(!intakeFlag){
            setintakeFlag(true)
        }else if(intakeFlag){
            setintakeFlag(false)
        }
        
    }
    const showapplication=()=>{
        if(!applicatioFlag){
            setapplicatioFlag(true)
        }else if(applicatioFlag){
            setapplicatioFlag(false)
        }
        
    }
    const showgre=()=>{
        if(!greFlag){
            setgreFlag(true)
        }else if(greFlag){
            setgreFlag(false)
        }
        
    }
    const showschool=()=>{
        if(!schoolFlag){
            setschoolFlag(true)
        }else if(schoolFlag){
            setschoolFlag(false)
        }
        
    }
    const showenglish=()=>{
        if(!englishScorFlag){
            setenglishScorFlag(true)
        }else if(englishScorFlag){
            setenglishScorFlag(false)
        }
        
    }
    const showDesipiline=()=>{
        if(!desipilineFlag){
            setdesipilineFlag(true)
        }else if(desipilineFlag){
            setdesipilineFlag(false)
        }
        
    }

    const allFilterShow=()=>{
        setAllFilterFlag(true)
    }
    const filteredItems = items.filter(item =>
        item.toLowerCase().includes(query.toLowerCase())
      );
    const handleClickOutside = (event) => {
        if(shortDiv){
            if (shortDiv.current && !shortDiv.current.contains(event.target)) {
                setShordropdown(false) 
            }
        }
        if(countryDiv){
            if (countryDiv.current && !countryDiv.current.contains(event.target)) {
                setcountryFlag(false) 
            }
        }
        if(inputdiv){
            if (inputdiv.current && !inputdiv.current.contains(event.target)) {
                setInputFlag(false)
            }
        }
        if(allfilterDiv){
            if (allfilterDiv.current && !allfilterDiv.current.contains(event.target)) {
                setAllFilterFlag(false)
            }
        }
      
      };
  
      const setImputValue =(value)=>{
        setQuery(value);
       
      }
      const getInputValue=(value)=>{
        setQuery(value);
        setInputFlag(true)

      }

    function hideModalhandler() {
        setModalIsVisible(false);
    }

    function showModalhandler(size) {
        setModalIsVisible(true);
        setModalSize(size);
    }
      
      
    document.addEventListener('mousedown', handleClickOutside);
    return(
        <>
            {modalIsVisible && (
                <SearchModal onShow={showModalhandler} size={modalSize}>
                    <ComparePrograms onClose={hideModalhandler} />
                </SearchModal>
            )}
            
        { allFilterFlag && (
            <div   className={styles['all-filter-background']}>
            <div ref={allfilterDiv} className={styles['all-filter-section']}>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showLocationFilter}>
                    <div className={styles['header-align']}> <img src={locattionIcon} alt='' /> <div className={styles['filter-heading']}> Location</div></div>
                    <div><img className={locationFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                
                {locationFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}

             
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showCourseType}>
                <div className={styles['header-align']}> <img src={courseIcon} alt='' /> <div className={styles['filter-heading']}> Course Type</div></div>
                    <div><img className={courseTypeFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {courseTypeFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showDesipiline}>
                <div className={styles['header-align']}> <img src={desiplineIcon} alt='' /> <div className={styles['filter-heading']}>Discipline</div></div>
                    <div><img className={desipilineFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {desipilineFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showtution}>
                <div className={styles['header-align']}> <img src={tutionIcon} alt='' /> <div className={styles['filter-heading']}> Tuition</div></div>
                    <div><img className={tutionFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {tutionFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showintake}>
                <div className={styles['header-align']}> <img src={intakeIcon} alt='' /> <div className={styles['filter-heading']}> Intake</div></div>
                    <div><img className={intakeFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {intakeFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showschool}>
                <div className={styles['header-align']}> <img src={schoolIcon} alt='' /> <div className={styles['filter-heading']}> School</div></div>
                    <div><img className={schoolFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {schoolFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showduration}>
                <div className={styles['header-align']}> <img src={durationIcon} alt='' /> <div className={styles['filter-heading']}> Duration</div></div>
                    <div><img className={durationFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {durationFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showinternship}>
                <div className={styles['header-align']}> <img src={internshipIcon} alt='' /> <div className={styles['filter-heading']}> Co-op and Internship</div></div>
                    <div><img className={internshipFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {internshipFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showenglish}>
                <div className={styles['header-align']}> <img src={englishIcon} alt='' /> <div className={styles['filter-heading']}> English Score</div></div>
                    <div><img className={englishScorFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {englishScorFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showgre}>
                <div className={styles['header-align']}> <img src={gmtIcon} alt='' /> <div className={styles['filter-heading']}> GMAT/GRE Score</div></div>
                    <div><img className={greFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {greFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
                <div className={styles['filter-sparate-border']}>
                <div className={styles['all-filter-content']} onClick={showapplication}>
                <div className={styles['header-align']}> <img src={applicationIcon} alt='' /><div className={styles['filter-heading']}> Application Fee</div></div>
                    <div><img className={applicatioFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></div>
                </div>
                {applicatioFlag && (
                   <div className={styles['dropdown-background']}>
                     {country.map(items=>(
                     <div className={styles['checkbox-alignment']}>
                     <input type="checkbox"></input>
                    <div>{items}</div>
                    </div>
                   ))}
                   </div>
                )}
                </div>
            <div>
            <div className={styles['clear-btn-postion']}>
            <button className={styles['clear-btn']}>Clear All</button>
            <button className={styles['see-program-btn']}>See Programs</button>
            </div>
           </div>
            </div>
            
</div>
        )}
          
        <div className={`${styles['filter_container']}`}>
        <div className={styles['tabs_container']}>
            <div onClick={()=>{getTabIndex(1)}} className={tabIndex===1? `${styles['selected_tabs_value']}  col-md-3`:`${styles['tabs_value']} col-md-3`}>AIME</div>
            <div onClick={()=>{getTabIndex(2)}} className={tabIndex===2? `${styles['selected_tabs_value']} col-md-3`:`${styles['tabs_value']} col-md-3`}>Programs</div>
            <div  onClick={()=>{getTabIndex(3)}} className={tabIndex===3? `${styles['selected_tabs_value']} col-md-3`:`${styles['tabs_value']} col-md-3`}>Institutes</div>
            <div onClick={()=>{getTabIndex(4)}} className={tabIndex===4? `${styles['selected_tabs_value']}  col-md-3`:`${styles['tabs_value']} col-md-3`}>Scholarship</div>
        </div>
        {tabIndex!==1 && (
 <div className={`${styles['search_filter_container']}`}>
 <div className="row mb-2 pb-3">
     <div className="col-md-12 col-lg-6 mb-2">
         <div className={styles['search-align']}>
             <div>
                {countryName==='Country' && (
              <button onClick={()=>{showCountry()}} className={styles['country-btn']}>{countryName} <span><img className={countryFlag? styles['rotateImage']:''} src={dropdownIcon} alt='' /></span>
              </button>
                )}
                 {countryName!=='Country' && (
              <button onClick={()=>{showCountry()}} className={styles['selected-country-btn']}>{countryName} <span><img className={countryFlag? styles['rotateImage']:''} src={whiteArrow} alt='' /></span>
              </button>
                )}
            
             {countryFlag && (
                <div ref={countryDiv} className={styles['countryDropDown']}>
             {country.map((item) => (
              
                         <div onClick={()=>{showCountry(item)}} className={styles['dropDown-aligment']}>
                             <div className={styles['dropdown-text']}>{item}</div>
                         </div>
                       
                     ))}
                       </div>
             )}
             
             </div>
             <div className={styles['input-width']}>
                 {tabIndex===2 && (
                    <div className={styles['input-width-value']} >
                     <input  value={query}
                     onChange={(e) => getInputValue(e.target.value)} name='program' placeholder="Search a Program" type="text" className={styles['input-serch']}></input>
                    {inputFlag && filteredItems?.length>0 && query?.length>2   && (
                        <div ref={inputdiv} className={styles['searchInputValue']}>
                        {filteredItems.map((item, index) => (
                         <div className={styles['dropdown-text']} key={index} onClick={()=>{setImputValue(item)}}>{item}</div>
                         ))}
                         </div>
                    )}
                     <div>
                    
                   </div>
                   </div>
                 )}
                 {tabIndex===3 && (
                             <div className={styles['input-width-value']} >
                             <input  value={query}
                             onChange={(e) => getInputValue(e.target.value)} name='institute' placeholder="Search an Institute" type="text" className={styles['input-serch']}></input>
                            {inputFlag && filteredItems?.length>0 && query?.length>2   && (
                                <div ref={inputdiv} className={styles['searchInputValue']}>
                                {filteredItems.map((item, index) => (
                                 <div className={styles['dropdown-text']} key={index} onClick={()=>{setImputValue(item)}}>{item}</div>
                                 ))}
                                 </div>
                            )}
                             <div>
                            
                           </div>
                           </div>
                 )}
                   {tabIndex===4 && (
                     <select name='scholarship' placeholder="Level of Study" value='' className={styles['input-serch']}>
                         <option value='1'> Institute 1</option>
                         <option value='2'> Institute 2</option>
                     </select>
                 )}
                 {tabIndex!==4 && (
                 <div><img className={styles['search-icon']} src={searchIcon} alt=''></img></div>
)}
             </div>
             <div>
                 <button  className={`${styles['primary-button']} primary-button `}>Search</button>
             </div>
         </div>
         

     </div>

     <div className="col-md-12 col-lg-6">
         <div className={styles['search-align-filter']}>
            { tabIndex===2 && (
              <div>
              <button className={styles['commom-btn']} onClick={() => showModalhandler('xl')}>Compare</button>
          </div>  
            )}
              { tabIndex===2 && (
             <div>
                 <button onClick={allFilterShow} className={styles['commom-btn']}> <span><img src={allfilterIcon} alt='' /></span> All Filters</button>
             </div>
              )}
             <div>
                 <button onClick={()=>{showSortDrop()}} className={styles['commom-btn']}>Sort by Alphabetical <span><img src={alphaBetIcon} alt='' /></span> </button>
                 {shordropdown && (
                     <div ref={shortDiv} className={styles['sortDropDown']}>
                     {sortAlphabet.map((item) => (
                         <div onClick={()=>{showSortDrop()}} className={styles['dropDown-aligment']}>
                             <div><img src={updownIcon} alt=''></img></div>
                             <div className={styles['dropdown-text']}>{item}</div>
                         </div>
                     ))}
                 </div>
                 )}
               
             </div>
         </div>

     </div>

 </div>

</div>
        ) }
       
        </div>
        <div>
        <div>
            { tabIndex===1 && (
                <AIME ></AIME>
            )}
            
        </div>
        <div>
        { tabIndex===2 && (
            <Programs ></Programs>
        )}
        </div>
        <div>
        { tabIndex===3 && (
            <Institutes ></Institutes>
        )}
        </div>
        <div>
        { tabIndex===4 && (
            <Scholarship ></Scholarship>
        )}
        </div>
        </div>

      
        </>
    )

}
export default SearchPage;