import React, { useState } from "react";
import classes from "./AboutModalBody.module.css";
import { Form } from "react-bootstrap";
import corssIcon from "../../../../../../Assets/Logo/cross-icon.svg";

function AboutModalBody({ title }) {
    //Interests
    const [interests, setInterests] = useState(["Design", "Graphics", "Painting", "Hiking"]);
    const [newInterest, setNewInterest] = useState("");

    //Skills
    const [suggestedSkills, setSuggestedSkills] = useState([
        "Graphic Design", "Teamwork", "Marketing", "Photoshop", "SEO", "Analytics", "Public-speaking", "Typing", "Motivation", "Networking"
    ]);

    //Competency
    const [suggestedCompetencies, setSuggestedCompetencies] = useState([
        "Critical Thinking and Problem Solving", "Communication and Collaboration",
        "Flexibility and Adaptability", "Initiative and Self-Direction"
    ]);

    const addInterest = () => {
        if (newInterest && !interests.includes(newInterest)) {
            setInterests([...interests, newInterest]);
            setNewInterest("");
        }
    };

    const removeInterest = (interest) => {
        setInterests(interests.filter(i => i !== interest));
    };

    return (
        <>
            {title === 'Interests' && (
                <div>
                    <Form>
                        <div className={classes["modal-body-block"]}>
                            <div className={classes["block-1"]}>
                                <div className={classes["search-bar"]}>
                                    <input type="text" placeholder="Add your interest...." value={newInterest} onChange={(e) => setNewInterest(e.target.value)} />
                                    <button className={`${classes[""]} primary-button`} type="button" onClick={addInterest}>Add</button>
                                </div>
                                <div>
                                    <Form.Check
                                        type="checkbox"
                                        id="custom-checkbox"
                                        label="I’m interested in content with these skills"
                                    />
                                </div>
                            </div>
                            <div className={classes["block-2"]}>
                                {interests.map((interest, index) => (
                                    <div key={index} className={classes["added-data"]}>
                                        <span className={classes["added-data-text"]}>{interest}</span>
                                        <img title="Remove" className={classes["corss-icon"]} src={corssIcon} onClick={() => removeInterest(interest)} alt=''></img>
                                    </div>
                                ))}
                            </div>
                            <div className={classes["block-3"]}>
                                <button className={`${classes["save-btn"]} primary-button`}>Save</button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}

            {title === 'Skills' && (
                <div>
                    <Form>
                        <div className={classes["modal-body-block"]}>
                            <div className={classes["block-1"]}>
                                <div className={classes["search-bar"]}>
                                    <input type="text" placeholder="Find your skill...." />
                                    <button className={`${classes[""]} primary-button`} type="submit">Add</button>
                                </div>
                            </div>
                            <div className={classes["block-2"]}>
                                <div className={classes["added-data"]}>
                                    <span className={classes["added-data-text"]}>Prototyping</span>
                                    <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img>
                                </div>
                                <div className={classes["added-data"]}>
                                    <span className={classes["added-data-text"]}>Hi-Fi</span>
                                    <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img>
                                </div>
                                <div className={classes["added-data"]}>
                                    <span className={classes["added-data-text"]}>Wireframing</span>
                                    <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img>
                                </div>
                                <div className={classes["added-data"]}>
                                    <span className={classes["added-data-text"]}>UI Design</span>
                                    <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img>
                                </div>
                                <div className={classes["added-data"]}>
                                    <span className={classes["added-data-text"]}>Creativity</span>
                                    <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img>
                                </div>
                            </div>
                            <div className={classes["block-4"]}>
                                <div>
                                    <Form.Check
                                        type="checkbox"
                                        id="custom-checkbox"
                                        label="I’m interested in content with these skills"
                                    />
                                </div>
                                <div className={classes["suggested-text"]}>Here are some suggested skills for you:</div>
                                <div className={classes["suggested-skills-list"]}>
                                    {suggestedSkills.map((skill, index) => (
                                        <div key={index} className={classes["suggested-skill"]}>
                                            {skill}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={classes["block-3"]}>
                                <button className={`${classes["save-btn"]} primary-button`}>Save</button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}

            {title === 'Competency' && (
                <div>
                    <Form>
                        <div className={classes["modal-body-block"]}>
                            <div className={classes["block-1"]}>
                                <div className={classes["search-bar"]}>
                                    <input type="text" placeholder="Add your competency...." />
                                    <button className={`${classes[""]} primary-button`} type="submit">Add</button>
                                </div>
                            </div>
                            <div className={classes["block-2"]}>
                                <div className={classes["added-data"]}>
                                    <span className={classes["added-data-text"]}>Creativity and Innovation</span>
                                    <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img>
                                </div>
                            </div>
                            <div className={classes["block-4"]}>
                                <div>
                                    <Form.Check
                                        type="checkbox"
                                        id="custom-checkbox"
                                        label="I’m interested in content with these competency"
                                    />
                                </div>
                                <div className={classes["suggested-text"]}>Here are some suggested competencies for you:</div>
                                <div className={classes["suggested-skills-list"]}>
                                    {suggestedCompetencies.map((skill, index) => (
                                        <div key={index} className={classes["suggested-skill"]}>
                                            {skill}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={classes["block-3"]}>
                                <button className={`${classes["save-btn"]} primary-button`}>Save</button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </>
    );
}

export default AboutModalBody;