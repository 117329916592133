import { Modal } from 'react-bootstrap';
import classes from "./Modal_Box.module.css";
import corssIcon from "../../../Assets/Logo/cross-icon.svg";

function Modal_Box({ children, onShow, onClose, title, removePadding }) {
    return (
        <Modal show={onShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className={classes.header}>
                <Modal.Title className={classes.title} id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
                <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={onClose}></img>
            </Modal.Header>
            <Modal.Body className={ removePadding ? 'p-0':''}>
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default Modal_Box;