import React, { useState } from "react";
import classes from "./About.module.css";
import editIcon from '../../../../../Assets/Logo/edit-icon.svg';
import addIcon from '../../../../../Assets/Logo/add-icon.svg';
import coinIcon from '../../../../../Assets/Logo/coin-icon.svg';
import corssIcon from '../../../../../Assets/Logo/cross-icon.svg';
import languageIcon from '../../../../../Assets/Logo/language-icon.svg';
import nameEdit from '../../../../../Assets/Logo/content-edit-icon.svg';
import locationIcon from '../../../../../Assets/Logo/location-icon.svg';
import contactIcon from '../../../../../Assets/Logo/contact-icon.svg';
import birthIcon from '../../../../../Assets/Logo/birth-icon.svg';
import statusIcon from '../../../../../Assets/Logo/status-icon.svg';
import starIcon from '../../../../../Assets/Logo/star-icon.svg';
import genderIcon from '../../../../../Assets/Logo/gender-icon.svg';
import calenderIcon from '../../../../../Assets/Logo/calender-icon.svg';
import graduationCap from '../../../../../Assets/Logo/graduation-cap-icon.svg';
import programIcon from '../../../../../Assets/Logo/program-icon.svg';
import lineIcon from '../../../../../Assets/Logo/line-hr.svg';
import deleteICon from '../../../../../Assets/Logo/delete-icon.svg';
import jobTitleIcon from '../../../../../Assets/Logo/job-title-icon.svg';
import upperArrowICon from '../../../../../Assets/Logo/uper-arrow-icon.svg';
import dropArrowICon from '../../../../../Assets/Logo/drop-down_icon.svg';
import emailIcon from '../../../../../Assets/Logo/email-icon.svg';
import Modal_Box from "../../../../GlobleShared/Modal/Modal_Box";
import AboutModalBody from "./About_ModalBody/AboutModalBody";
const interests=["Design", "Graphics", "Printing"];
const skills=["Design", "Graphics", "Printing"]
const competencies=["Creativity and Innovation: 5", "Creativity and Innovation: 6"]
function About() {
  const [modalIsVisible, setModalIsVisible] = useState();
  const [selectedHeading, setselectedHeading] = useState("");

  function hideModalhandler() {
    setModalIsVisible(false);
  }

  function showModalhandler(header) {
    setselectedHeading(header)
    setModalIsVisible(true);
  }
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id) => {
    setOpenDropdown(prevOpenDropdown => prevOpenDropdown === id ? null : id);
  };
  
  const [openDropdownForCompany, setOpenDropdownForCompany] = useState(null);

  const toggleDropdownForCompany = (id) => {
    setOpenDropdownForCompany(prevOpenDropdown => prevOpenDropdown === id ? null : id);
  };
  
  
  
  const [educationData, setEducationData] = useState([
    {
      id: 1,
      school: 'School',
      program: 'Program',
      gradingScheme: 'Grading Scheme',
      gpa: 'GPA/UGC',
      yearStart: 'Year Start',
      yearEnd: 'Year End',
      location: 'Location',
    },
    {
      id: 2,
      school: 'School',
      program: 'Program',
      gradingScheme: 'Grading Scheme',
      gpa: 'GPA/UGC',
      yearStart: 'Year Start',
      yearEnd: 'Year End',
      location: 'Location',
    },
  ]);
  const [workData, setWorkData] = useState([
    {
      id: 1,
      company: 'Company',
      jobTitle: 'Job Title',
      start: 'Start',
      end: 'End',
      location: 'Location',
    },
  ]);
  return (
    <>
    {modalIsVisible && (
      <Modal_Box onClose={hideModalhandler} onShow={showModalhandler} title={selectedHeading}>
        <AboutModalBody title={selectedHeading} />
      </Modal_Box>
  )}

    <div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div>
            <p className={classes["heading-text"]}>Overview</p>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
            <span className={classes["black_text"]}>jane</span>
          </div>
          <div>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
            <span className={classes["black_text"]}>Smith</span>
          </div>
          <div>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={emailIcon} alt=''></img></span>
            <span className={classes["black_text"]}>fakeEmail@email.com</span>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={nameEdit} alt=''></img></span>
            <span className={classes["grey-text"]}>Tagline</span>
          </div>
          <div>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={birthIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>Birthday</span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={calenderIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>Age</span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={genderIcon} alt=''></img></span>
            <span className={classes["black_text"]}>Male</span>
          </div>
          <div>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={statusIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>Marital Status</span>
          </div>
          <div>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div className="col-md-4">
            <span><img className={classes["over-view-icon"]} src={locationIcon} alt=''></img></span>
            <span className={classes["black_text"]}>Origin : </span>
            <span className={classes["grey-text"]}>Country</span>
            <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          <div className="col-md-4">
          <span className={classes["grey-text"]}>State/Province</span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          <div>
          <span className={classes["grey-text"]}>City</span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div className="col-md-4">
            <span><img className={classes["over-view-icon"]} src={locationIcon} alt=''></img></span>
            <span className={classes["black_text"]}>Residence : </span>
            <span className={classes["grey-text"]}>Country</span>
            <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          <div className="col-md-4">
          <span className={classes["grey-text"]}>State/Province</span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          <div>
          <span className={classes["grey-text"]}>City</span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img className={classes["over-view-icon"]} src={contactIcon} alt=''></img></span>
            <span className={classes["grey-text"]}>Contact</span>
          </div>
          <div>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>About</p>
            </div>
            <div>
                <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
            </div>
          </div>
          <div className={classes["about-section"]}>
            <p className={classes["about-section-text"]}>
              Hi there, I’m studying Computer Science and UoP. I have studied
              hard and I’m giving a go of it to try and get abetter job with a
              better education. I’m looking to connect with like minded people
              to hlp learn and take in these courses. Connect with me and we’ll
              make a large digital study group
            </p>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div>
            <p className={classes["heading-text"]}>Language</p>
          </div>
          <div>
         <div className={classes["icon-alignment"]}>
          <div>
            <span><img  className={classes["languageIcon-image-size"]} src={languageIcon} alt=''></img></span>
            <span className={classes["blank-content-text"]}>Primary Language</span> <span className={classes["star-mark"]}>*</span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          <div className={classes["icon-alignment"]}>
          <div>
            <span><img  className={classes["languageIcon-image-size"]} src={languageIcon} alt=''></img></span>
            <span className={classes["blank-content-text"]}>Secodary Language</span>
          </div>
          <div>
          <span className={classes["coin-count"]}>5</span>
          <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
          <img className={classes["edit-icon"]} src={editIcon} alt=''></img>
          </div>
          </div>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Education</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
                <img className={classes["image-size"]} src={addIcon} alt=''></img>
            </div>
          </div>
            <div>
              {educationData.map((school, index) => (
                <div key={school.id}>
                  <div className={classes["icon-alignment"]}>
                    <div className="d-flex">
                      <div><span><img className={classes["educationIcon-image-size"]} src={graduationCap} alt=''></img></span></div>
                      <div  className={` ${classes["grey-text"]}`}>{school.school}</div>
                      <div className="ms-4">
                        <span>
                          <img
                            className={classes["edit-icon"]}
                            src={openDropdown == school.id ? upperArrowICon : dropArrowICon} 
                            alt=''
                            onClick={() => toggleDropdown(school.id)}
                          />
                        </span>
                      </div>
                      <div className="ms-4">
                        <span><img  className={classes["edit-icon"]} src={deleteICon} alt=''></img></span>
                      </div>
                    </div>
                    <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                  </div>
                  {openDropdown === school.id && (
                    <div className="dropDownContainer">
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={programIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.program}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={starIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.gradingScheme}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={starIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.gpa}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.yearStart}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{school.yearEnd}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                      <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["educationIcon-image-size"]} src={locationIcon} alt=''></img></span></div>
                          <div className={`${classes["grey-text"]}`}>{school.location}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                    </div>
                  )}
                      {index < educationData.length - 1 && <img className={`${classes.imageLineHeight}`} src={lineIcon}></img>}
                </div>
              ))}
            </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Work</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["workIcon-image-size"]} src={coinIcon} alt=''></img></span>
                <img className={classes["image-size"]} src={addIcon} alt=''></img>
            </div>
          </div>
          <div>
              {workData.map((rowData) => (
                <div key={rowData.id}>
                     <div className={classes["icon-alignment"]}>
                    <div className="d-flex">
                      <div><span><img className={classes["workIcon-image-size"]} src={graduationCap} alt=''></img></span></div>
                      <div className={`ms-3 + ${classes["grey-text"]}`}>{rowData.company}</div>
                      <div className="ms-4">
                        <span>
                          <img
                            className={classes["edit-icon"]}
                            src={openDropdownForCompany == rowData.id ? upperArrowICon : dropArrowICon} 
                            alt=''
                            onClick={() => toggleDropdownForCompany(rowData.id)}
                          />
                        </span>
                      </div>
                      <div className="ms-4">
                        <span><img className={classes["edit-icon"]} src={deleteICon} alt=''></img></span>
                      </div>
                    </div>
                    <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                  </div>
                  {openDropdownForCompany === rowData.id && (
                    <div className="dropDownContainer">
                         <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={jobTitleIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{rowData.jobTitle}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                         <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{rowData.start}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                         <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={calenderIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{rowData.end}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                         <div className={classes["icon-alignment"]}>
                        <div className="d-flex">
                          <div className="ms-4"><span><img className={classes["workIcon-image-size"]} src={locationIcon} alt=''></img></span></div>
                          <div className={` ${classes["grey-text"]}`}>{rowData.location}</div>
                        </div>
                        <div className="mx-4"><span> <img className={classes["edit-icon"]} src={editIcon} alt=''></img></span></div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Interests</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
                <img className={classes["image-size"]} src={addIcon} alt='' onClick={() => showModalhandler('Interests')}></img>
            </div>
          </div>
          {interests?.length===0 && (
             <div>
             <p className={classes["blank-content-text"]}>
             Add your interests
             </p>
           </div>
          )}
         { interests?.length>0 && (
             <div className={classes["added-content"]}>
                {interests.map(item => (
                    <div className={classes["div_position"]}>
                    <div className={classes["added-content-text"]}>
                        {item}
                    </div>
                    <div> <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img></div>
                    </div>

                ))}
             </div>
          )}

        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Skills</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
                <img className={classes["image-size"]} src={addIcon} alt='' onClick={() => showModalhandler('Skills')}></img>
            </div>
          </div>
          <div>
          {skills?.length===0 && (
             <div>
             <p className={classes["blank-content-text"]}>
             Add your skills
             </p>
           </div>
          )}
         { skills?.length>0 && (
             <div className={classes["added-content"]}>
                {skills.map(item => (
                    <div className={classes["div_position"]}>
                    <div className={classes["added-content-text"]}>
                        {item}
                    </div>
                    <div> <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img></div>
                    </div>

                ))}
             </div>
          )}
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
        <div className={classes["heading-align"]}>
            <div>
            <p className={classes["heading-text"]}>Competencies</p>
            </div>
            <div>
                <span className={classes["coin-count"]}>5</span>
                <span><img  className={classes["coin-image-size"]} src={coinIcon} alt=''></img></span>
                <img className={classes["image-size"]} src={addIcon} alt='' onClick={() => showModalhandler('Competency')}></img>
            </div>
          </div>
          <div>
            {competencies?.length===0 && (
             <div>
             <p className={classes["blank-content-text"]}>
             Add your competencies
             </p>
           </div>
          )}
         { competencies?.length>0 && (
             <div className={classes["added-content"]}>
                {competencies.map(item => (
                    <div className={classes["div_position"]}>
                    <div className={classes["added-content-text"]}>
                        {item}
                    </div>
                    <div> <img title="Remove" className={classes["corss-icon"]} src={corssIcon} alt=''></img></div>
                    </div>

                ))}
             </div>
          )}
          </div>
        </div>
      </div>
    </div></>
  );
}
export default About;
