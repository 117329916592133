import React from "react";
import classes from "./ProgramDetails.module.css"; // Adjust the import based on your file structure
import program_details_image from "../../../../Assets/Images/program_details_image.png";
import heartIcon from "../../../../Assets/Logo/heartIcon.svg";
import SimilarPrograms from "./SimilarPrograms/SimilarPrograms"

function ProgramDetails() {

  const data = {
    profile: {
      name: "Douglas College",
      location: "New Westminster, British Columbia, Canada",
      imageSrc: program_details_image,
    },
    program: {
      topic: "Accounting",
      moreInfo: "More Details... (MSMUnify)",
    },
    description: {
      paragraphs: [
        "Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program. Students will study a well-rounded curriculum that includes classes in accounting, communications, computing science, economics, finance and marketing.",
        "Graduates gain the skills to secure employment in fields including financial accounting, cost accounting and financial management. Graduates interested in furthering their accounting careers can transfer seamlessly into Douglas College's Bachelor of Business Administration in Accounting program. This diploma also satisfies the entrance requirements of the Chartered Professional Accountants (CPA) Advanced Certificate in Accounting and Finance (ACAF) program of studies and partially satisfies its requirements for admission into the CPA Professional Education Program (PEP).",
        "Students enrolled in this program may be eligible for a Co-operative Education designation. Co-operative Education involves alternating full-time academic and work terms. For information, contact the Co-operative Education Office.",
      ],
    },
    collegeInfo: {
      title: "Douglas College",
      paragraphs: [
        "Founded in 1970, Douglas College is the largest degree-granting college in British Columbia, Canada, educating about 20,000 students per year. The College has two major campuses in Metro Vancouver (New Westminster and Coquitlam) as well as several smaller training centres in Surrey, Burnaby and Maple Ridge. With both main campuses directly on SkyTrain lines, Douglas is one of the most accessible post-secondary institutions in Metro Vancouver, drawing students from across the region.",
        "Douglas College offers the most bachelor’s degrees and post-degree programs of any college in B.C., and is noted for combining the academic foundations of a university with the career-ready skills of a college. Over 900 experienced, teaching-focused faculty bring both academic rigor and real-world experience to the classroom at Douglas. Outside the classroom, over 900 other employees work together to create a friendly, inspiring, supportive and respectful environment.",
      ],
    },
    similarPrograms: {
      title: "Douglas College",
    },
    panel: {
      rank: "Ranked #1",
      graduationLikelihood: "95%",
      peopleLikeYou: "403 of 435 graduated",
      applicationFee: "CAD $30",
      compareNumber: 8,
      heartIcon: heartIcon,
    },
    information: {
      tuition: "$13,980 / year",
      livingCost: "$20,635 / year",
      programLength: "2 year College Diploma (Option for co-op)",
      applicationFee: "CAD $30",
    },
    intake: [
      { status: "Open", date: "Sep 2024" },
      { status: "Open", date: "Jan 2025" },
      { status: "Likely Open", date: "May 2025" },
      { status: "Likely Open", date: "Sep 2025" },
    ],
    education: {
      minEducation: "Grade 12 / High School",
      minGPA: "60.0%",
    },
    language: {
      IELTS: "6.5",
      TOEFL: "83.0",
      PTE: "56.0",
      Duolingo: "110",
    },
    ranking: {
      rank: "Rank: #1",
      description: "This program was ranked #1 based on these criteria:",
      criteria: [
        "Math Skills",
        "Analytical Mind",
        "Organized",
        "Logic and Reasoning",
        "Finances",
        "Critical Thinking",
      ],
    },
  };

  return (
    <div className={`${classes["programDetails_main_container"]} row`}>
      <div
        className={`${classes["programDetails_section"]} col-md-9 col-lg-9}`}
      >
        <div className={`${classes["programDetails_left_section"]}`}>
          <div className={`${classes["programDetails_section1"]}`}>
            <div className={classes["profileCard"]}>
              <div className={classes["card_coverImage"]}>
                <img src={data.profile.imageSrc} alt="" />
              </div>
              <div className={classes["card_info"]}>
                <div className={classes["name"]}>{data.profile.name}</div>
                <div className={classes["description"]}>
                  {data.profile.location}
                </div>
              </div>
            </div>
          </div>

          <div className={`${classes["programDetails_section2"]}`}>
            <div className={classes["block_content"]}>
              <div className={classes["block_topic"]}>{data.program.topic}</div>
              <div className={classes["block_more_info"]}>
                {data.program.moreInfo}
              </div>
            </div>
          </div>

          <div className={`${classes["programDetails_section3"]}`}>
            <div className={`row ${classes["main-row"]}`}>
              <div className={`col-8 ${classes["customCol8"]}`}>
                <div className={classes["customCol8_content"]}></div>
              </div>
              <div className={`col-2 ${classes["customCol2"]}`}>
                <div className={classes["innerDiv"]}></div>
                <div className={classes["innerDiv"]}></div>
                <div className={`mb-0 ${classes["innerDiv"]}`}></div>
              </div>
              <div className={`col-2 ${classes["customCol2"]}`}>
                <div className={classes["innerDiv"]}></div>
                <div className={classes["innerDiv"]}></div>
                <div className={`mb-0 ${classes["innerDiv"]}`}></div>
              </div>
            </div>
          </div>

          <div className={`${classes["programDetails_section4"]}`}>
            <div className={`${classes["program_description_content"]}`}>
              <div className={`${classes["program_title"]}`}>
              Program Description
              </div>
              <div className={`${classes["program_description"]}`}>
                {data.description.paragraphs.map((para, index) => (
                  <p key={index}>{para}</p>
                ))}
              </div>
            </div>
          </div>

          <div className={`${classes["programDetails_section5"]}`}>
            <div className={`${classes["program_description_content"]}`}>
              <div className={`${classes["program_title"]}`}>
                {data.collegeInfo.title}
              </div>
              <div className={`${classes["program_description"]}`}>
                {data.collegeInfo.paragraphs.map((para, index) => (
                  <p key={index}>{para}</p>
                ))}
              </div>
            </div>
          </div>

          {/* Similar programs sections */}
          <div className={`${classes["programDetails_section6"]}`}>
            <div
              className={`${classes["programDetails_similarPrograms_content"]}`}
            >
              <div className={`${classes["program_title"]}`}>
              Similar Programs
              </div>
              <div className={`${classes["program_slider"]}`}>
                 <SimilarPrograms/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${classes["programDetails_section"]} col-md-3 col-lg-3}`}
      >
        <div className={`${classes["programDetails_right_section"]}`}>
          <div className={classes["programDetails_panel-container"]}>
            <div className={classes["compare-section"]}>
              <span className={classes["compare-button"]}>
                <button>Compare</button>
              </span>
              <span className={classes["compare-number"]}>
                {data.panel.compareNumber}
              </span>
              <span className={classes["favorite-icon"]}>
                <img src={data.panel.heartIcon} alt="" />
              </span>
            </div>
            <h3 className={classes["panel-text-first"]}>{data.panel.rank}</h3>
            <p className={classes["panel-text-first"]}>
              <span className={classes["panel-text-second"]}>
                {data.panel.graduationLikelihood}
              </span>
              Likelihood of Graduation{" "}
            </p>
            <p className={classes["panel-text-first"]}>People like You</p>
            <p className={classes["panel-text-second"]}>
              {data.panel.peopleLikeYou} graduated
            </p>
            <p className={classes["panel-text-first"]}>Application Fee</p>
            <p className={classes["panel-text-second"]}>
              {data.panel.applicationFee}
            </p>
            <button className={classes["apply-button"]}>Apply</button>
          </div>

          <div className={classes["main-content-heading"]}>Information</div>
          <div className={classes["information_section-container"]}>
            <div>
              <div className={classes["info-key"]}>Program Level</div>
              <div className={classes["info-value"]}>
                2 year Undergraduate Diploma
              </div>
            </div>

            <div>
              <div className={classes["info-key"]}>Tuition</div>
              <div className={classes["info-value"]}>
                {data.information.tuition}
              </div>
            </div>

            <div>
              <div className={classes["info-key"]}>Cost of Living</div>
              <div className={classes["info-value"]}>
                {data.information.livingCost}
              </div>
            </div>
            <div>
              <div className={classes["info-key"]}>Program Length</div>
              <div className={classes["info-value"]}>
                {data.information.programLength}
              </div>
            </div>
          </div>

          <div className={classes["main-content-heading"]}>Program Intake</div>
          <div className={classes["program_intake_section-container"]}>
            {data.intake.map((intake, index) => (
              <div key={index} className={classes["program-intake-content"]}>
                <div className={classes["program-intake-key"]}>
                  {intake.status}
                </div>
                <div className={classes["program-intake-value"]}>
                  {intake.date}
                </div>
              </div>
            ))}
          </div>

          <div className={classes["main-content-heading"]}>Education</div>
          <div className={classes["education_section-container"]}>
            <div>
              <div className={classes["edu-key"]}>
                Minimum Level of Education Completed
              </div>
              <div className={classes["edu-value"]}>
                {data.education.minEducation}
              </div>
            </div>

            <div>
              <div className={classes["edu-key"]}>Minimum GPA</div>
              <div className={classes["edu-value"]}>
                {data.education.minGPA}
              </div>
            </div>
          </div>

          <div className={classes["main-content-heading"]}>Language</div>
          <div className={classes["language_section-container"]}>
            <div className={classes["language-content"]}>
              <div className={classes["language-key"]}>IELTS</div>
              <div className={classes["language-value"]}>
                {data.language.IELTS}
              </div>
            </div>
            <div className={classes["language-content"]}>
              <div className={classes["language-key"]}>TOEFL</div>
              <div className={classes["language-value"]}>
                {data.language.TOEFL}
              </div>
            </div>
            <div className={classes["language-content"]}>
              <div className={classes["language-key"]}>PTE</div>
              <div className={classes["language-value"]}>
                {data.language.PTE}
              </div>
            </div>
            <div className={classes["language-content"]}>
              <div className={classes["language-key"]}>Duolingo</div>
              <div className={classes["language-value"]}>
                {data.language.Duolingo}
              </div>
            </div>
          </div>

          <div className={classes["main-content-heading"]}>Ranking</div>
          <div className={classes["ranking_section-container"]}>
            <div className={classes["ranking-title"]}>{data.ranking.rank}</div>
            <div className={classes["ranking-description"]}>
              {data.ranking.description}
            </div>
            <ul className={classes[""]}>
              {data.ranking.criteria.map((item, index) => (
                <li key={index} className={classes["ranking-item"]}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>


      <div className={`${classes["programDetails_container-part2"]}`}>
            <div className={`${classes["section"]} `}>
                <h2>Best Country For Studies</h2>
            </div>
            <div className={`${classes["section"]} `}>
                <h2>Testimonials</h2>
            </div>
            <div className={`${classes["section"]} `}>
                <h2>Blog</h2>
            </div>
        </div>

    </div>
  );
}

export default ProgramDetails;
