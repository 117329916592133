import axiosInstance from "./Interceptor";
const PostServices = {
  uploadFile: async (data) => {
    try {
      const response = await axiosInstance.post('/common/upload-file', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  addPost: async (data) => {
    try {
      const response = await axiosInstance.post('/post/add', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },

  getPostList: async (data) => {
    try {
      const response = await axiosInstance.post('/post/list', data);
      return response.data;
    } catch (error) {
      throw error.response.data
    }
  },
  
}
export default PostServices;