import React, { useState } from "react";
import classes from "./ApplicationList.module.css"
import { Form, Table } from "react-bootstrap";
import srName from "../../../../../Assets/Logo/srnum.svg"
import sortName from "../../../../../Assets/Logo/sort.svg";
import actionIcon from "../../../../../Assets/Logo/table-action.svg";
import { useNavigate } from "react-router-dom";
import ApplicationCategory from "../../../../GlobleShared/ApplicationCategoryModal/ApplicationCategory";

const ApplicationList = () => {
    const navigate= useNavigate();
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const tableData = {
        columns: ["Application ID", "Status", "Application Created", "Submission Date",
                  "Institution Name", "Email ID", "Contact Number", "Document Status"],
        rows: [
          {
            "Application ID": "294310",
            "Status": "Application Created",
            "Application Created": "30/08/2024",
            "Submission Date": "Pending to Submit Unify",
            "Institution Name": "Cape Breton University",
            "Email ID": "demostudent@msmsquaremedia.com",
            "Contact Number": "+1-(268)- 2165485558",
            "Document Status": "Incomplete Application",
          },
          {
            "Application ID": "294310",
            "Status": "Application Created",
            "Application Created": "30/08/2024",
            "Submission Date": "Pending to Submit Unify",
            "Institution Name": "Cape Breton University",
            "Email ID": "demostudent@msmsquaremedia.com",
            "Contact Number": "+1-(268)- 2165485558",
            "Document Status": "Incomplete Application",
          },
          {
            "Application ID": "294310",
            "Status": "Application Created",
            "Application Created": "30/08/2024",
            "Submission Date": "Pending to Submit Unify",
            "Institution Name": "Cape Breton University",
            "Email ID": "demostudent@msmsquaremedia.com",
            "Contact Number": "+1-(268)- 2165485558",
            "Document Status": "Incomplete Application",
          },
          {
            "Application ID": "294310",
            "Status": "Application Created",
            "Application Created": "30/08/2024",
            "Submission Date": "Pending to Submit Unify",
            "Institution Name": "Cape Breton University",
            "Email ID": "demostudent@msmsquaremedia.com",
            "Contact Number": "+1-(268)- 2165485558",
            "Document Status": "Incomplete Application",
          },
          {
            "Application ID": "294310",
            "Status": "Application Created",
            "Application Created": "30/08/2024",
            "Submission Date": "Pending to Submit Unify",
            "Institution Name": "Cape Breton University",
            "Email ID": "demostudent@msmsquaremedia.com",
            "Contact Number": "+1-(268)- 2165485558",
            "Document Status": "Incomplete Application",
          },
        ],
      };
      const addApplication =(value)=>{
        navigate('/member/application/add/'+value)

      }
      const showModalhandler=()=>{
        setModalIsVisible(true);

    }
    const hideModalhandler=(value)=>{
        setModalIsVisible(value);

    }
    

    return (
        <>
            <div className={classes["application-list-container"]}>
                <div className={classes["section-1"]}>
                    <div className={classes["heading"]}>MSM Application List</div>
                    <div>
                        <button onClick={showModalhandler} className={`${classes["new-application-btn"]} secondary-button`}>+ New Application</button>
                    </div>
                </div>
                <div className={classes["section-2"]}>
                    <Form className={classes["radio-button-block"]}>
                        <Form.Check
                            inline
                            label="On Campus"
                            type="radio"
                            id="radio-1"
                            className={classes["radio-btn"]}
                        />
                        <Form.Check
                            inline
                            label="Online/Blended"
                            type="radio"
                            id="radio-2"
                            className={classes["radio-btn"]}
                        />
                    </Form>
                </div>
                <div className={classes["section-3"]}>
                    <Table responsive className={classes.customTable}>
                        <thead>
                            <tr>
                                {tableData.columns.map((column, index) => (
                                    <th key={index}>
                                        {index === 0 ? (
                                            <div className={classes.thAndIconContainer}>
                                                <span className={classes.columnText}>{column}</span>
                                                <img src={srName} alt="Sr Name" className={classes.icon} />
                                            </div>
                                        ) : (
                                            <div className={classes.thAndIconContainer}>
                                                <span className={classes.columnText}>{column}</span>
                                                <img src={sortName} alt="Sort" className={classes.icon} />
                                            </div>
                                        )}
                                    </th>
                                ))}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {tableData.columns.map((column, colIndex) => (
                                        <td key={colIndex}>{row[column]}</td>
                                    ))}
                                    <td><img src={actionIcon} alt="Action" className={classes.icon} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

        {modalIsVisible && (
        <ApplicationCategory onClose={hideModalhandler} onShow={modalIsVisible} navigate={addApplication}>
        </ApplicationCategory>
        )}
            </div>
        </>
    )
}

export default ApplicationList;