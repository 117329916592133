import React from 'react'
import classes from './Programs.module.css'
import universityLogo from "../../../Assets/Logo/dougl-icon.svg";

function Programs({program}) {


  return (
    <div>
       <div className={classes["card-section"]} >
                                    <div className={classes["section-1"]}>
                                        <div className={classes["card-header-block-1"]}>
                                            <div className={classes["card-rank"]}> <span className={classes["rank-text"]}>Ranked: #1</span></div>
                                            <div className={classes["card-header"]}>
                                                <div className={classes["university-icon"]}><img src={universityLogo} alt="Profile" /></div>
                                                <div className={classes["university-detail"]}>
                                                    <div className={classes["university-name"]}>Douglas College</div>
                                                    <div className={classes["university-address"]}>New Westminster,</div>
                                                    <div className={classes["university-address"]}>British Columbia, Canada</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes["card-header-block-2"]}>
                                            <div className={classes["program-name"]}>Accounting</div>
                                            <div className={classes["program-detail-text"]}>
                                                Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program.
                                                Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes["section-2"]}>
                                        <div className={classes["program-detail-section-1"]}>
                                            <div>
                                                <div className={classes["intake-header"]}>Intake</div>
                                                <div className={classes["detail"]}>Sep 2024</div>
                                                <div className={classes["detail"]}>Sep 2024</div>
                                            </div>
                                            <div className={classes["detail"]}>2 Years</div>
                                            <div className={classes["detail"]}>Bachelors Degree</div>
                                            <div className={classes["detail"]}>CAD $14,110 / Year</div>
                                        </div>
                                        <div className={classes["program-detail-section-2"]}>
                                            <div>
                                                <div className={classes["header"]}>Application Fee</div>
                                                <div className={classes["detail"]}>CAD $30</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>People like You</div>
                                                <div className={classes["detail"]}>403 of 435 graduated</div>
                                            </div>
                                            <div>
                                                <div className={classes["header"]}>Likelihood of Graduation</div>
                                                <div className={classes["detail"]}>95%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    </div>
  )
}

export default Programs