import React, { useState } from "react";
import classes from "./User_Account.module.css";
import Marketing from "./Marketing/Marketing";
import avatarImage from "../../../../Assets/Images/backgroung_profile_image.jpg";
import profile_picture from "../../../../Assets/Images/profile_picture.png";
import edit_profile from "../../../../Assets/Logo/edit_profile.svg";
import Posts from "../../../Common/Posts/Posts";
import Settings from "./Settings/Settings";
import Connections from "./Connections/Connections";
import About from "./About/About";
import Documents from "./Documents/Documents";
import Modal_Box from "../../../GlobleShared/Modal/Modal_Box";
import ProfileModel from "./Settings/Section/ProfileModel";
import Applications from "./Applications/Applications";
function UserAccount() {
  const [tab, setTab] = useState("About");
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [selectedHeading, setselectedHeading] = useState("");

  function hideModalhandler() {
    setModalIsVisible(false);
  }

  function showModalhandler(header) {
    setselectedHeading(header)
    setModalIsVisible(true);
  }
  // const [container, setContainer] = useState("connections");
  return (
    <div className={`${classes["user_account"]} row `}>
      <div className={` ${classes["user_account_section"]} col-md-9 col-lg-9`}>
        <div className={classes["user_account_info"]}>
          <div className={classes["background_image"]}>
            <div className={classes["background_image_pic"]}>
              <img src={avatarImage} alt="background-image " />
            </div>
            <div className={classes["profile_pic"]}>
              <span className={classes["user_profile_pic"]}>
                <img src={profile_picture} alt="User Profile_Picture" />
              </span>
              <span className={classes["edit_profile_icon"]} onClick={()=>setModalIsVisible(true)}>
                <img src={edit_profile} alt="" />
              </span>
            </div>
          </div>
          {modalIsVisible && (
          <Modal_Box removePadding={true} onClose={hideModalhandler} onShow={showModalhandler} title={selectedHeading}>
            <ProfileModel/>
          </Modal_Box>
          )}
          <div className={`row ${classes["user_details"]}`}>
            <div className={`col-md-6 ${classes["user_personalDetails"]}`}>
              <p>
                Jane Smith <span>(She/Her)</span>
              </p>
              <p>fakeEmail@email.com</p>
            </div>

            <div className={`col-md-6 ${classes["user_highlights"]}`}>
              <p>Studying Computer Science</p>
              <p>Highlights</p>
            </div>
          </div>

          <div className={classes["user_connections"]}>172 Connections</div>

          <div className={classes["section-line"]}>
            <hr />
          </div>

          <div className={classes["user_navigation"]}>
            <button
              className={tab === "About" ? classes["active_button"] : ""}
              onClick={() => setTab("About")}
            >
              About
            </button>
            <button
              className={tab === "Documents" ? classes["active_button"] : ""}
              onClick={() => setTab("Documents")}
            >
              Documents
            </button>
            <button
              className={tab === "Connections" ? classes["active_button"] : ""}
              onClick={() => setTab("Connections")}
            >
              Connections
            </button>
            <button
              className={tab === "Application" ? classes["active_button"] : ""}
              onClick={() => setTab("Application")}
            >
              Applications
            </button>
            <button
              className={tab === "Posts" ? classes["active_button"] : ""}
              onClick={() => setTab("Posts")}
            >
              Posts
            </button>
            <button
              className={tab === "Settings" ? classes["active_button"] : ""}
              onClick={() => setTab("Settings")}
            >
              Settings
            </button>
          </div>
        </div>
        {tab === "About" && (
          <div >
            <About />
          </div>
        )}

        {tab === "Posts" && (
          <div className={classes["posts_section"]}>
            <Posts />
          </div>
        )}
        {tab === "Settings" && (
          <div>
            <Settings />
          </div>
        )}
        {tab === "Connections" && (
          <div>
            <Connections  />
          </div>
        )}
        {tab === "Documents" && (
          <div>
            <Documents />
          </div>
        )}
        {tab === "Application" && (
          <div>
            <Applications />
          </div>
        )}
        {/* <div className={classes["user_navigation"]}>
          <button>About</button>
          <button>Documents</button>
          <button onClick={()=>setContainer("connections")}>Connections</button>
          <button>Application</button>
          <button>Posts</button>
          <button onClick={()=>setContainer("settings")}>Settings</button>
        </div> */}
        {/* {container === "settings" && <Settings />}
        {container === "connections" && <Connections />} */}
      </div>

      <div
        className={` ${classes["user_account_marketing"]} col-md-3 col-lg-3`}
      >
        <div className={classes["marketing_section"]}>
          <Marketing />
        </div>
      </div>
    </div>
  );
}
export default UserAccount;
