import React, { useState } from "react";
import { Card, Table } from 'react-bootstrap';
import classes from "./DeferralRequests.module.css";
import srName from "../../../../../Assets/Logo/srnum.svg";
import sortName from "../../../../../Assets/Logo/sort.svg";
import leftDropDown from "../../../../../Assets/Logo/BackArrow.svg";

const DeferralRequests = () => {
  const tableData = {
    columns: ["Application ID", "Deferral Intake", "Deferral Date", "Name", "Program", "Application Program Category", "Institution", "Status"],
    rows: [
      {
        "Application ID": "294310",
        "Deferral Intake": "Dec-2024",
        "Deferral Date": "30/08/2024",
        "Name": "Demo Student",
        "Program": "Advanced Diploma in Data Science & Artificial Intelligence",
        "Application Program Category": "Online",
        "Institution": "Canadian College for Higher Studies",
        "Status": "N/A",
      },
      {
        "Application ID": "294310",
        "Deferral Intake": "Dec-2024",
        "Deferral Date": "30/08/2024",
        "Name": "Demo Student",
        "Program": "Advanced Diploma in Data Science & Artificial Intelligence",
        "Application Program Category": "Online",
        "Institution": "Canadian College for Higher Studies",
        "Status": "N/A",
      },
    ],
  };
  const handleViewRequest = (id) => {
    console.log("add detail button action");
    setDeferralId(id)
  };
  const [deferralId, setDeferralId] = useState(null);
  const selectedDeferral = tableData.rows.find(row => row["Application ID"] === deferralId);  
  return (
    <div className={classes.container}>
      <div className={classes.heading}>MSM Deferral Requests</div>
     {deferralId && 
     <div className={`mt-3 mb-3 ${classes.backButton}`} onClick={() => setDeferralId(null)}>
        <span><img src={leftDropDown} alt="" /></span><span>Back</span>
      </div>}
      {deferralId === null ?
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                  <img
                    src={index === 0 ? srName : sortName}
                    alt={index === 0 ? "Sr Name" : "Sort"}
                    className={classes.icon}
                  />
                </div>
              </th>
            ))}
            <th>Deferral Details</th>
          </tr>
        </thead>
        <tbody>
          {tableData.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
              <td>
                <span 
                  className={classes.actionBtn}
                  onClick={() => handleViewRequest(row["Application ID"])}>
                  View
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table> :(
         <div className={classes.detailContainer}>
         <div className={`${classes.CardLine} row`}>
           <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Application ID</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Application ID"]}
                 </Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Deferral Intake</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Deferral Intake"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Created By</Card.Title>
                 <Card.Text className={classes.cardText}> {selectedDeferral["Name"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Deferral Date</Card.Title>
                 <Card.Text className={classes.cardText}> {selectedDeferral["Deferral Date"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Status</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Status"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Name</Card.Title>
                 <Card.Text className={classes.cardText}> {selectedDeferral["Name"]} Student</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Institution</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Institution"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Program</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Program"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Application Program Category</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Program"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Intake</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Deferral Intake"]}</Card.Text>
               </Card.Body>
             </Card>
             </div>
             <div className="col-md-4"> 
             <Card className={classes.card}>
               <Card.Body>
                 <Card.Title className={classes.cardTitle}>Student ID</Card.Title>
                 <Card.Text className={classes.cardText}>{selectedDeferral["Application ID"]}
                 </Card.Text>
               </Card.Body>
             </Card>
             </div>
         </div>
       </div>
     )}
   </div>
 );
};

export default DeferralRequests;
