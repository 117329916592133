import React, { useState } from "react";
import classes from "./Applications.module.css";
import companyLogoOne from '../../../../../Assets/Logo/comp-logo-1.svg';
import companyLogoTwo from '../../../../../Assets/Logo/comp-logo-2.svg';
import companyLogoThree from '../../../../../Assets/Logo/comp-logo-3.svg';
function Applications() {
  const [applications] = useState([
    {
      img: companyLogoOne,
      name: "Douglas College",
      description: "Computer Science",
      progress: 60,
      backgroundColor: "#000000",
    },
    {
      img: companyLogoTwo,
      name: "Simon Fraser",
      description: "Computer Science",
      progress: 75,
      backgroundColor: "#C8102E", 
    },
    {
      img: companyLogoThree,
      name: "VIU",
      description: "Computer Science",
      progress: 40,
      backgroundColor: "#003366",
    },
  ]);

  return (
    <div>
      <div className={classes["common-section"]}>
        <div className={classes["grid-container"]}>
          {applications.map((app, index) => (
            <div
              key={index}
              className={classes.card}
              style={{ backgroundColor: app.backgroundColor }}
            >
              <img src={app.img} alt={app.name} className={classes.logo} />
              <div className={classes.name}>{app.name}</div>
              <div className={classes.description}>{app.description}</div>
              <div className={classes.progress}>
                <div
                  className={classes["progress-bar"]}
                  style={{ width: `${app.progress}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Applications;
