import React, { useState } from 'react';
import classes from './ComposePost.module.css';
import video from "../../../../../Assets/Logo/video.svg";
import photo from "../../../../../Assets/Logo/photo.svg";
import link from "../../../../../Assets/Logo/iconLink.svg";
import camera from "../../../../../Assets/Logo/camera.svg";
import CommonPost from '../CommonPost/CommonPost'; // Import CommonPost

function ComposePost() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle the input click
  const handleInputClick = () => {
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={classes["composePost_main_container"]}>
      <div className={classes["composePost_block"]}>
        <input
          type="text"
          placeholder="Start a Post"
          className={classes["post_input"]}
          onClick={handleInputClick}
        />
        <div className={classes["button_container"]}>
          <button className={classes["post_button"]}>
            <img src={photo} alt="Photo icon" />
            <div>Photo</div>
          </button>
          <button className={classes["post_button"]}>
            <img src={video} alt="Video icon" />
            <div>Video</div>
          </button>
          <button className={classes["post_button"]}>
            <img src={camera} alt="Camera icon" />
            <div>Camera</div>
          </button>
          <button className={classes["post_button"]}>
            <img src={link} alt="Link icon" />
            <div>Link</div>
          </button>
        </div>
      </div>

      {/* Render CommonPost */}
      <CommonPost isModalOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}

export default ComposePost;