import React, { useState } from "react";
import { Button, Card, Table } from 'react-bootstrap';
import classes from "./RetriveDeleteApplication.module.css";
import srName from "../../../../../Assets/Logo/srnum.svg";
import sortName from "../../../../../Assets/Logo/sort.svg";
import SearchModal from "../../Search/SearchModal/SearchModal";
import corssIcon from "../../../../../Assets/Logo/cross-icon.svg";

const RetriveDeleteApplication = () => {
  const tableData = {
    columns: ["Application ID", "Student Name", "Passport Number", "Program", "Institution", "Status"],
    rows:[
        {
          "Application ID": "294310",
          "Student Name": "Demo Student Student",
          "Passport Number": "98332048",
          "Program": "Advanced Diploma in Data Science & Artificial Intelligence",
          "Institution": "Advanced Diploma in Data Science & Artificial Intelligence",
          "Status": "",
        },
        {
          "Application ID": "294174",
          "Student Name": "Demo Student Student",
          "Passport Number": "98332048",
          "Program": "Bachelor of Business Administration",
          "Institution": "Cape Breton University",
          "Status": ""
        },
        {
          "Application ID": "294176",
          "Student Name": "Demo Student Student",
          "Passport Number": "98332048",
          "Program": "Bachelor of Business Administration",
          "Institution": "Cape Breton University",
          "Status": ""
        },
        {
          "Application ID": "294177",
          "Student Name": "Demo Student Student",
          "Passport Number": "98332048",
          "Program": "Bachelor of Business Administration",
          "Institution": "Cape Breton University",
          "Status": ""
        }
      ],      
  };
  const [showModal, setShowModal] = useState(false);

  const handleViewRequest = (id) => {
    setDeferralId(id);
    setShowModal(true);
  };

  const handleConfirm = () => {
    console.log("Refund confirmed for Application ID:", deferralId);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const [deferralId, setDeferralId] = useState(null);
  const selectedDeferral = tableData.rows.find(row => row["Application ID"] === deferralId);  
  return (
    <div className={classes.container}>
      <div className={classes.heading}>MSM Retrieve Deleted Applications</div>
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                  <img
                    src={index === 0 ? srName : sortName}
                    alt={index === 0 ? "Sr Name" : "Sort"}
                    className={classes.icon}
                  />
                </div>
              </th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
              <td>
                <span 
                  className={classes.actionBtn}
                  onClick={() => handleViewRequest(row["Application ID"])}>
                  Retrive
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table> 
      <SearchModal
        onShow={showModal}
        size="md"
        >  
        <div className={classes.ModelContainer}>
        <div className={`${classes.headerContainer}`}>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={handleClose}></img>
        </div>
          <div className={classes.title}>Are you sure you want to retrieve this application?</div>
        <div className={classes.btnContainer}>
          <Button
          className={`${classes.btnNo}`}
            onClick={handleClose}>
            No
          </Button>
          <Button
          className={`${classes.btnYes}`}
            onClick={handleConfirm}>
            Yes
          </Button>
        </div>
        </div> 
      </SearchModal>
   </div>
 );
};

export default RetriveDeleteApplication;
