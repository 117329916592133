import React from 'react';
import styles from './Progress.module.css';  

const Progress = () => {
  const progressData = {
    profile: 75,
    eligibility: 66,
    documents: 66,
    institute: 23,
  };

  return (
    <div className={styles['progress-container']}>
      <div className={styles['progress-header']}>
        <span>Progress:</span>
        <a href="../../../../../Pages/Home/User_Account/About">View More...</a>
      </div>
      <ProgressBar className={styles.labelName} label="Profile" percentage={progressData.profile} />
      <ProgressBar className={styles.labelName} label="Eligibility" percentage={progressData.eligibility} />
      <ProgressBar className={styles.labelName} label="Documents" percentage={progressData.documents} />
      <ProgressBar className={styles.labelName} label="Institute" percentage={progressData.institute} />
    </div>
  );
};

const ProgressBar = ({ label, percentage }) => {
  return (
    <div className={styles['progress-bar']}>
      <span className={styles['progress-label']}>{label}:</span>
      <div className={styles['progress-percent-container']}>
        <span className={styles['progress-percent']}>{percentage}%</span>
      </div>
      <div className={styles['progress']}>
        <div className={styles['progress-fill']} style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
};

export default Progress;
