
import classes from './Scholarship.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { Form } from "react-bootstrap";
import universityLogo from "../../../../../Assets/Logo/dougl-icon.svg";
import crossIconWhite from "../../../../../Assets/Logo/crose-whiteMini.svg";

const Scholarship = () => {

    const programs = [
        {
            id: 1
        },
        {
            id: 2
        },
        {
            id: 3
        },
        {
            id: 4
        },
        {
            id: 5
        },
    ];

    return (
        <>
            <div>
                <div className="row">

                    {/* Secondary Filters */}
                    <div className="col-md-2">
                        <div>
                            <Accordion className="filter-section" defaultActiveKey={['0', '1', '2']} alwaysOpen>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Type</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Check
                                                type="checkbox"
                                                id="ct-scholarship"
                                                label="Scholarship"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="ct-bursary"
                                                label="Bursary"
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Institute</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Check
                                                type="checkbox"
                                                id="alexander-college"
                                                label="Alexander College"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="acadia-college"
                                                label="Acadia College"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="douglas-college"
                                                label="Douglas College"
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                id="okanagan-college"
                                                label="Okanagan College"
                                            />
                                              <Form.Check
                                                type="checkbox"
                                                id="sfu"
                                                label="SFU"
                                            />
                                              <Form.Check
                                                type="checkbox"
                                                id="royal-roads"
                                                label="Royal Roads"
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>


                            </Accordion>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="col-md-10">
                    <div>
                                <button className={classes['NameBudge']}> 2 - year bachelors 
                                    <span>  <img
                    className={classes['crossIcon']}
                    src={crossIconWhite}
                    alt=""
                  /></span>
                                </button>
                            </div>
                        <div className={classes["program-cards"]}>
                     
                            {programs.map((program) => (
                                <div className={classes["card-section"]} key={program.id}>
                                    <div className={classes["section-1"]}>
                                        <div className={classes["card-header-block-1"]}>
                                            <div className={classes["card-header"]}>
                                                <div className={classes["university-icon"]}><img src={universityLogo} alt="Profile" /></div>
                                                <div className={classes["university-detail"]}>
                                                    <div className={classes["university-name"]}>Douglas College</div>
                                                    <div className={classes["university-address"]}>New Westminster,</div>
                                                    <div className={classes["university-address"]}>British Columbia, Canada</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes["card-header-block-2"]}>
                                            <div className={classes["program-name"]}>4.0 GPA Scholarship</div>
                                            <div className={classes["program-detail-text"]}>
                                                Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program.
                                                Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes["section-2"]}>
                                        <div className={classes["program-detail-section-1"]}>
                                            <div>
                                                <div className={classes["intake-header"]}>Intake</div>
                                                <div className={classes["detail"]}>Sep 2024</div>
                                            </div>
                                            <div>
                                            <div className={classes["detail"]}>2 Years</div>
                                            <div className={classes["detail"]}>Bachelors Degree</div>
                                            </div>
                                            
                                            <div className={classes["detail"]}>CAD $14,110 / Year</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scholarship;