
import React from "react";
import "./App.css";
import Main from "./Components/Pages/Main/Main";
import "./Styles/Style.css"
import { InputProvider } from "./Components/Pages/Home/Dashboard/DashboardSearch/GlobalSearch";

function App() {
  return (
    <div>
       <InputProvider>
       <Main></Main>
    </InputProvider>
    </div>
  );
}

export default App;
