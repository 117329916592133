import React, { useState } from "react";
import classes from "./Documents.module.css";
import editIcon from "../../../../../Assets/Logo/edit-icon.svg";
import deleteICon from "../../../../../Assets/Logo/delete-icon.svg";
import addIcon from "../../../../../Assets/Logo/add-icon.svg";
import coinIcon from "../../../../../Assets/Logo/coin-icon.svg";
import locationIcon from "../../../../../Assets/Logo/location-icon.svg";
import calenderIcon from "../../../../../Assets/Logo/calender-icon.svg";
import passportIcon from "../../../../../Assets/Logo/passport-icon.svg";
import countryIcon from "../../../../../Assets/Logo/country-icon.svg";
import governmentIdIcon from "../../../../../Assets/Logo/goverment-id-icon.svg";
import lineIcon from "../../../../../Assets/Logo/line-hr.svg";
import visaIcon from "../../../../../Assets/Logo/Visa-icon.svg";
import eyeIcon from "../../../../../Assets/Logo/eye-icon.svg";
import documentIcon from "../../../../../Assets/Logo/document-icon.svg";
import upperArrowICon from "../../../../../Assets/Logo/uper-arrow-icon.svg";
import dropArrowICon from "../../../../../Assets/Logo/drop-down_icon.svg";

function Documents() {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id) => {
    setOpenDropdown((prevOpenDropdown) => (prevOpenDropdown === id ? null : id));
  };
  const [educationData, setEducationData] = useState([
    {
      id: 1,
      visa: "Visa",
      country: "Country",
      document: "Document",
    },
  ]);
  return (
    <div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={passportIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>Passport #</div>
            </div>
            <div>
              <span className={classes["coin-count"]}>5</span>
              <span>
                <img
                  className={classes["coin-image-size"]}
                  src={coinIcon}
                  alt=""></img>
              </span>
              <img
                className={classes["edit-icon"]}
                src={editIcon}
                alt=""></img>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={countryIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>Country</div>
            </div>
            <div>
              <img
                className={classes["edit-icon"]}
                src={editIcon}
                alt=""></img>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={calenderIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>Expiration Date</div>
            </div>
            <div>
              <img
                className={classes["edit-icon"]}
                src={editIcon}
                alt=""></img>
            </div>
          </div>
          <div className={classes["icon-alignment"]}>
            <div className="d-flex">
              <div>
                <span>
                  <img
                    className={classes["educationIcon-image-size"]}
                    src={governmentIdIcon}
                    alt=""></img>
                </span>
              </div>
              <div className={` ${classes["grey-text"]}`}>Government Id</div>
            </div>
            <div>
              <img
                className={classes["edit-icon"]}
                src={editIcon}
                alt=""></img>
            </div>
          </div>
        </div>
      </div>
      <div className={classes["common-section"]}>
        <div className={classes["common-section-css"]}>
          <div className={classes["heading-align"]}>
            <div>
              <p className={classes["heading-text"]}>Visa</p>
            </div>
            <div>
              <span className={classes["coin-count"]}>5</span>
              <span>
                <img
                  className={classes["coin-image-size"]}
                  src={coinIcon}
                  alt=""></img>
              </span>
              <img
                className={classes["image-size"]}
                src={addIcon}
                alt=""></img>
            </div>
          </div>
          <div>
            {educationData.map((visa, index) => (
              <div key={visa.id}>
                <div className={classes["icon-alignment"]}>
                  <div className="d-flex">
                    <div>
                      <span>
                        <img
                          className={classes["educationIcon-image-size"]}
                          src={visaIcon}
                          alt=""></img>
                      </span>
                    </div>
                    <div className={` ${classes["grey-text"]}`}>{visa.visa}</div>
                    <div className="ms-4">
                      <span>
                        <img
                          className={classes["edit-icon"]}
                          src={openDropdown == visa.id ? upperArrowICon : dropArrowICon}
                          alt=""
                          onClick={() => toggleDropdown(visa.id)}
                        />
                      </span>
                    </div>
                    <div className="ms-4">
                      <span>
                        <img
                          className={classes["edit-icon"]}
                          src={deleteICon}
                          alt=""></img>
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <span>
                      {" "}
                      <img
                        className={classes["edit-icon"]}
                        src={editIcon}
                        alt=""></img>
                    </span>
                  </div>
                </div>
                {openDropdown === visa.id && (
                  <div className="dropDownContainer">
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex">
                        <div className="">
                          <span>
                            <img
                              className={classes["educationIcon-image-size"]}
                              src={locationIcon}
                              alt=""></img>
                          </span>
                        </div>
                        <div className={` ${classes["grey-text"]}`}>{visa.country}</div>
                      </div>
                      <div>
                        <span>
                          {" "}
                          <img
                            className={classes["edit-icon"]}
                            src={editIcon}
                            alt=""></img>
                        </span>
                      </div>
                    </div>
                    <div className={classes["icon-alignment"]}>
                      <div className="d-flex">
                        <div className="">
                          <span>
                            <img
                              className={classes["educationIcon-image-size"]}
                              src={documentIcon}
                              alt=""></img>
                          </span>
                        </div>
                        <div className={` ${classes["grey-text"]}`}>{visa.document}</div>
                        <div className="">
                          <span>
                            <img
                              className={classes["educationIcon-image-size"]}
                              src={eyeIcon}
                              alt=""></img>
                          </span>
                        </div>
                      </div>
                      <div>
                        <span>
                          {" "}
                          <img
                            className={classes["edit-icon"]}
                            src={editIcon}
                            alt=""></img>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {index < educationData.length - 1 && (
                  <img
                    className={`${classes.imageLineHeight}`}
                    src={lineIcon}></img>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Documents;
