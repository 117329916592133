import React, { useEffect, useRef, useState } from "react";
import classes from "./SubComponents.module.css";
import blockIcon from "../../.../../../../../../Assets/Logo/block-icon.svg";
import kingIcon from "../../.../../../../../../Assets/Logo/king.svg";
import secureIcon from "../../.../../../../../../Assets/Logo/secure.svg";
import turnModeIcon from "../../.../../../../../../Assets/Logo/turn_into_mode-icon.svg";
import disconnectIcon from "../../.../../../../../../Assets/Logo/disconnect-icon.svg";
import reportIcon from "../../.../../../../../../Assets/Logo/report-icon.svg";
import messageIcon from "../../.../../../../../../Assets/Logo/message-icon.svg";
import moreIcon from "../../.../../../../../../Assets/Logo/more-icon.svg";
import userPic from "../../.../../../../../../Assets/Images/comment_user_pic.png";
import ConnectionService from "../../../../../../Services/ConnectionService";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ChatWindow from "../../../../../GlobleShared/Chat/ChatWindow";

const ProfileCardInner = ({
  hidePopupBox,
  connectionId,
  imgSrc,
  name,
  description,
  isPopupVisible,
  onTogglePopup,
  onHidePopup,
  disconnectIsTrue,
  status,
  groupMembersFlag,
  onOpenChat
}) => {

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [connectionIdvalue, setconnectionIdvalue] = useState();
  const [statusId, setStatusId] = useState();
  const [btnTextName, setbtnTextName] = useState();
  const [confirmationText , setConfirmationText]= useState('')
  const [isLoading, setLsLoading] = useState(false);
  const refDiv=useRef(null)
  const navigate =useNavigate()
  const getConfirme =()=>{
    statusUpdate()
  }
  const showModalhandler=(value, status, name)=>{
    setModalIsVisible(true);
    setconnectionIdvalue(value);
    setStatusId(status)
    setbtnTextName(name)
    if(status===4){
      setConfirmationText('Are you sure, you want to report the content of the selected contact?')
    }
  
    if(status===5){
      setConfirmationText('Are you sure, you want to block the content of the selected contact?')
    }
  
    if(status===6){
      setConfirmationText('Are you sure, you want to remove the selected contact?')
    }
  
  }
  const hideModalhandler=(value)=>{
    setModalIsVisible(false);
  }
  
  const statusUpdate=()=>{
    let data={
      connectionId: connectionIdvalue,
      connectionStatusId: statusId
    }
    setLsLoading(true)
    ConnectionService.updateConnection(data).then(res=>{
      setModalIsVisible(false);
      setLsLoading(false)
      toast.success(res?.message)
      onHidePopup()
    }).catch(error=>{
      setLsLoading(false)
      toast.success(error?.message)
    })
  
  }

  const viewProfileSection = () => {
    navigate('/member/profileView');
}

  const handleClickOutside = (event) => {
    if(refDiv){
        if (refDiv.current && !refDiv.current.contains(event.target)) {
          hidePopupBox()
        }
    }
  }
  document.addEventListener('mousedown', handleClickOutside);
  return (
    <div className={classes.card}>
      <div className={classes.card_content}>
        <div className={classes.card_coverImage}>
          <img onClick={viewProfileSection}
            src={imgSrc ? imgSrc: userPic}
            alt={`${name}'s profile`}
            className={
              disconnectIsTrue
                ? classes.disconnectIsTrue + " " + classes.profileImage
                : classes.profileImage
            }
          />
        </div>
        <div className={classes.card_info}>
          <div onClick={viewProfileSection} className={classes.name}>{name}
           {groupMembersFlag && ( <span><img src={status} alt="" /></span>)}
          </div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>
        <div className={classes.buttons}>
          <div className={classes['menu-icon']} onClick={onTogglePopup}>
            <img
              className={classes.moreBtn}
              src={moreIcon}
              alt="More options"
            />
            {isPopupVisible && (
              <>
              <div ref={refDiv} className={classes.popup}>
                <div className={classes.popupOption}  onClick={()=>{showModalhandler(connectionId, 6, 'Remove')}}>
                  <img src={disconnectIcon} alt="Remove Contact" /> 
                  { groupMembersFlag ? (<>Remove</>) : (<>Remove Contact</>)}
                </div>
                <div className={classes.popupOption}  onClick={()=>{showModalhandler(connectionId, 5, 'Block')}}>
                  <img src={blockIcon} alt="Block" />Block Content
                </div>
                <div className={classes.popupOption} onClick={()=>{showModalhandler(connectionId, 4, 'Report')}}>
                  <img src={reportIcon} alt="Report" />Report Content
                </div>
                {groupMembersFlag && (
                   <div className={classes.popupOption} onClick={onHidePopup}>
                   <img src={turnModeIcon} alt="Turn into Mod" />Turn into Mod
                 </div>
                )}
              </div>
              </>
            )}
          </div>
          <div>
            <img onClick={onOpenChat}
              className={classes.messageBtn}
              src={messageIcon}
              alt="Send message"
            />
          </div>
        </div>

        {modalIsVisible && (
        <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible} 
        getConfirmation={getConfirme}>
        </ConfirmationModal>
      )}
        {isLoading && (
        <Loader></Loader>
      )}
    </div>
  );
};

const SubConnections = ({groupMembersFlag}) => {
  const [profiles, setProfiles] = useState([]);

  const [activePopup, setActivePopup] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginated, setPaginated] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLsLoading] = useState(false);
  const initialized = useRef(false)
  const [chatWindows, setChatWindows] = useState([]);

  const handleTogglePopup = (id) => {
    setActivePopup((prevId) => (prevId === id ? null : id));
  };

  const handleHidePopup = () => {
    refreshConnection(page,limit)
  };


  const getConnection=(pageValue)=>{
    let data={
      limit: limit,
      page: pageValue,
      paginated: paginated,
      searchTerm: ""
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...profiles,...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }
  const refreshConnection=(pageNum,limit)=>{
    let data={
      limit: pageNum*limit,
      page: 1,
      paginated: paginated,
      searchTerm: ""
    }
    setLsLoading(true)
    ConnectionService.getConnectionList(data).then(res=>{
      setProfiles([...res?.list]);
      setTotalCount(res?.totalCount)
      setLsLoading(false)
    }).catch(error=>{
      setLsLoading(false)
    })
  }
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getConnection(page);
    }
    // return()=>{
    //   getConnection(page);
    // }
  }, []);

  const getdataReload=()=>{
    refreshConnection(page,limit)
  }
  const viewMore=()=>{
    getConnection(page+1)
    setPage(page+1)

  }

  const hidePopup=()=>{
    setActivePopup()
  }

  const handleOpenChat = (profile) => {
    setChatWindows((prevChats) => {
      if (!prevChats.some((chat) => chat.userConnectionId === profile.userConnectionId)) {
        return [...prevChats, { ...profile, isMinimized: false }];
      }
      return prevChats;
    });
  };
  const handleCloseChat = (id) => {
    setChatWindows((prevChats) => prevChats.filter((chat) => chat.userConnectionId !== id));
  };

  const handleMinimizeChat = (id) => {
    setChatWindows((prevChats) => prevChats.map((chat) => (chat?.userConnectionId === id ? { ...chat, isMinimized: !chat.isMinimized } : chat)));
  };

  return (
    <div >
      {profiles.length>0 && (
        <div className={groupMembersFlag ? classes.groupMembersContainer : classes.container}>
            {profiles.map((profile, index) => (
              <ProfileCardInner
              key={profile?.userConnectionId}
              connectionId={profile?.userConnectionId}
              backgroundImage={profile?.profileBGImage}
              imgSrc={profile?.profileImage}
              name={profile?.displayName}
              description={profile?.aboutUs}
              connections={profile?.connections}
              isPopupVisible={activePopup === profile?.userConnectionId}
              onTogglePopup={() => handleTogglePopup(profile?.userConnectionId)}
              onHidePopup={handleHidePopup}
              onOpenChat={() => handleOpenChat(profile)}
              disconnectIsTrue={false}
              index={index}
              groupMembersFlag= {groupMembersFlag}
              getConfirmationValue={getdataReload}
              hidePopupBox={hidePopup}
              />
            ))}
              <div className={classes.chatContainer}>
          <div className={`${classes.forChatScreen} row`}>
            <div className="col-md-3"></div>
            {chatWindows.slice(0, 2).map((chat, index) => (
              <div
                key={chat?.userConnectionId}
                className={`col-md-4`}>
                <ChatWindow
                  id={chat?.userConnectionId}
                  name={chat?.displayName}
                  profile={chat?.profileImage}
                  onClose={() => handleCloseChat(chat?.userConnectionId)}
                  onMinimize={() => handleMinimizeChat(chat?.userConnectionId)}
                  isMinimized={chat.isMinimized}
                />
              </div>
            ))}
            <div className="col-md-1">
              {chatWindows.map((chat) => (
                <div key={chat?.userConnectionId}>
                  <img
                    className={classes.profilePicBottom}
                    src={chat?.profileImage}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
           </div>
      )}
            <div>
                 {(totalCount!==profiles?.length && profiles.length>0) && (
                <div className="text-center mt-2 mb-2">
                <button onClick={viewMore} className={classes['view-more']}>View more</button>
                  </div>
              )}
            </div>
      {profiles.length===0 && (
        <div className={classes['no-data']}>
          <p> No Connection Found</p>
          </div>
      ) }

        {isLoading && (
        <Loader></Loader>
      )}
     
    </div>
  );
};
export default SubConnections;
