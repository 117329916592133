import React, { useRef, useState } from "react";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import classes from "./common.module.css";
import userPic from "../../.../../../../../Assets/Images/comment_user_pic.png";
import blockIcon from "../../.../../../../../Assets/Logo/block-icon.svg";
import disconnectIcon from "../../.../../../../../Assets/Logo/disconnect-icon.svg";
import reportIcon from "../../.../../../../../Assets/Logo/report-icon.svg";
import messageIcon from "../../.../../../../../Assets/Logo/message-icon.svg";
import moreIcon from "../../.../../../../../Assets/Logo/more-icon.svg";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import ConnectionService from "../../../../../Services/ConnectionService";
import { toast } from "react-toastify";
import Loader from "../../../../GlobleShared/Loader/Loader";
const ConnectionProfileCard = ({hidePopupBox, connectionId, imgSrc, onOpenChat,name, description, isPopupVisible, onTogglePopup, onHidePopup, totalCards, index}) => {
  const navigate = useNavigate();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [connectionIdvalue, setconnectionIdvalue] = useState();
  const [statusId, setStatusId] = useState();
  const [btnTextName, setbtnTextName] = useState();
  const [confirmationText , setConfirmationText]= useState('')
  const [isLoading, setLsLoading] = useState(false);
  const refDiv=useRef(null)
  const viewProfileSection = () => {
    navigate('/member/profileView');
}

const getConfirme =()=>{
  statusUpdate()
}
const showModalhandler=(value, status, name)=>{
  setModalIsVisible(true);
  setconnectionIdvalue(value);
  setStatusId(status)
  setbtnTextName(name)
  if(status===4){
    setConfirmationText('Are you sure, you want to report the content of the selected contact?')
  }

  if(status===5){
    setConfirmationText('Are you sure, you want to block the content of the selected contact?')
  }

  if(status===6){
    setConfirmationText('Are you sure, you want to remove the selected contact?')
  }

}
const hideModalhandler=(value)=>{
  setModalIsVisible(false);
}

const statusUpdate=()=>{
  let data={
    connectionId: connectionIdvalue,
    connectionStatusId: statusId
  }
  setLsLoading(true)
  ConnectionService.updateConnection(data).then(res=>{
    setLsLoading(false)
    toast.success(res?.message)
    onHidePopup()
    setModalIsVisible(false);
  }).catch(error=>{
    setLsLoading(false)
    toast.success(error?.message)
  })

}
const handleClickOutside = (event) => {
  if(refDiv){
      if (refDiv.current && !refDiv.current.contains(event.target)) {
        hidePopupBox()
      }
  }
}
document.addEventListener('mousedown', handleClickOutside);

  return (
    <>
      <div className={classes.card} >
        <div className={classes.card_content}>
          <div className={classes.card_coverImage}>
            <img onClick={viewProfileSection}
              src={imgSrc ? imgSrc : userPic}
              alt={`${name}'s profile`}
              className={classes.profileImage}
            />
          </div>
          <div  className={classes.card_info}>
            <div onClick={viewProfileSection} className={classes.name}>{name}</div>
            <div className={classes.description}>{description}</div>
          </div>
        </div>
        <div className={classes.buttons}>
          <div className={classes['menu-icon']} onClick={onTogglePopup}>
            <img
              className={classes.moreBtn}
              src={moreIcon}
              alt="More options"
            />
            {isPopupVisible && (
              <div  ref={refDiv} className={classes.popup}>
                  <div 
                  className={classes.popupOption}
                  onClick={()=>{showModalhandler(connectionId, 6, 'Remove')}}>
                  <img
                    src={disconnectIcon}
                    alt="Remove Contact"
                  />{" "}
                  Remove Contact
                </div>
                <div 
                  className={classes.popupOption}
                  onClick={()=>{showModalhandler(connectionId, 5, 'Block')}}>
                  <img
                    src={disconnectIcon}
                    alt="Block"
                  />{" "}
                  Block Content
                </div>
        
                <div
                  className={classes.popupOption}
                  onClick={()=>{showModalhandler(connectionId, 4, 'Report')}}>
                  <img
                    src={blockIcon}
                    alt="Report"
                  />{" "}
                  Report Content
                </div>
              </div>
            )}
          </div>
          <div onClick={onOpenChat}>
            <img
              className={classes.messageBtn}
              src={messageIcon}
              alt="Send message"
            />
          </div>
        </div>
      </div>
      {index < totalCards - 1 ? <div className={classes["brokenLine"]}></div>: <div className="mb-4"></div> }

        {modalIsVisible && (
        <ConfirmationModal onClose={hideModalhandler} confirmationText={confirmationText} btnText={btnTextName} onShow={modalIsVisible} 
        getConfirmation={getConfirme}>
        </ConfirmationModal>
      )}
      {isLoading && (
        <Loader></Loader>
      )}
      
    </>
  );
};
export default ConnectionProfileCard;
