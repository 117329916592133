import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classes from "./CommonPost.module.css";
import userImage from "../../../../../Assets/Images/picture1.png"; // Path to user image
import crossWhite from "../../../../../Assets/Logo/crose-white.svg"; // Path to cross white icon
import dropdownIcon from "../../../../../Assets/Logo/drop-down-icon-2.svg"; // Path to dropdown icon
import icon1 from "../../../../../Assets/Logo/post-video-icon.svg";
import icon3 from "../../../../../Assets/Logo/like.svg";
import icon4 from "../../../../../Assets/Logo/heartIcon.svg";
import textIcon from "../../../../../Assets/Logo/like.svg";
import dummyImageIcon from "../../../../../Assets/Logo/dummy-image-icon.svg"; // Path to dummy image icon
import emojiIcon from "../../../../../Assets/Logo/emogi1.svg"; // Path to emoji icon
import { toast } from "react-toastify";
import Picker from 'emoji-picker-react';
import { Modal } from "react-bootstrap";
import ConfirmationModal from "../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import PostServices from "../../../../../Services/PostServices";
import Loader from "../../../../GlobleShared/Loader/Loader";


function CommonPost({ isModalOpen, closeModal }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState();
  const [selectedType, setSelectedType] = useState('');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const reactQuillRef = useRef(null);
  const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const allowedVideoTypes = ['video/mp4'];
  const [videoList, setVideoList]=useState()
  const [imageList, setImageList]=useState([])
  const fileInputRef = useRef(null);
  const [confirmationBox, setconfirmationBox]=useState(false)
  const [tempImage, setTempImage]=useState([])
  const [imageUrl, setImageUrl]=useState([])
  const [isLoading, setIsLoading]=useState(false)

  useEffect(() => {
    if (reactQuillRef.current) {
      reactQuillRef.current.getEditor();
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeParentModel = () => {
    closeModal();
    setEditorContent('');
    setSelectedImages([]);
    setSelectedVideos([]);
    setImageList([]);
    setVideoList('')
    setTempImage([])
  };

  const openImageModal = (value) => {
    setSelectedImages(imageList);
    setSelectedVideos(videoList);
    setSelectedType(value);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  const handleImageChange = (event) => {
    const uploadFile=Array.from(event.target.files)
    const invalidFiles = uploadFile.filter(file => {
      if(selectedType==='video'){
        if (!allowedVideoTypes.includes(file.type)) {
          return true;
        }
      }
      if(selectedType==='image'){
        if (!allowedTypes.includes(file.type)) {
          return true;
        }
      }
     
      return false;
    });
 
    if (invalidFiles.length > 0) {
      if(selectedType==='video'){
        toast.error('Invalid file type.')
      }
      if(selectedType==='image'){
        toast.error('Selected file format : PNG, JPEG and JPG.')
      }
 
     
    }else{
    if(selectedType==='image'){
      if(event.target.files?.length>0){
        setSelectedVideos('')
        setVideoList('')
       
      }
      if(selectedImages.length < 5){
        const files = Array.from(event.target.files);
        setTempImage(files)
        fileInputRef.current.value = null;
        const imagePromises = files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        });
 
        Promise.all(imagePromises).then((images) => {
          setSelectedImages((prevImages) => [...prevImages, ...images]);
        });
      } else {
        toast.error("Maximum 5 files you can upload");
        fileInputRef.current.value = null;
      }
    } else if (selectedType === 'video') {
      const file = event.target.files[0];
      setTempImage(Array.from(event.target.files))
      if(event.target.files[0]){
        setSelectedImages([]);
        setImageList([])
      }
      fileInputRef.current.value = null;
      if (file) {
          const fileUrl = URL.createObjectURL(file);
          setSelectedVideos(fileUrl);
      }
    }
  }
 
  };
 

  const insertMedia = () => {
    const quill = reactQuillRef.current.getEditor();
    const length = quill.getLength();

    // selectedImages.forEach((image) => {
    //   quill.insertEmbed(length, "image", image);
    // });

    // selectedVideos.forEach((videoUrl) => {
    //   const videoHtml = `<video controls src="${videoUrl}"></video>`;
    //   quill.clipboard.dangerouslyPasteHTML(length, videoHtml);
    // });
    if(selectedType==='video'){
      setVideoList(selectedVideos)
    }
    if(selectedType==='image'){
      setImageList(selectedImages)
    }
    closeImageModal();
  };

  const deleteImageFromPreview = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setTempImage((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const removeImage= (index) => {
    setImageList((prevImages) => prevImages.filter((_, i) => i !== index));
    setTempImage((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const deleteVideoFromPreview = (index) => {
    setSelectedVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const onEmojiClick = (event, emojiObject) => {
    const quill = reactQuillRef.current.getEditor();
    if (quill) {
      quill.focus();
      const selection = quill.getSelection(true); 
      const cursorPosition = selection ? selection.index : quill.getLength(); 
      quill.insertText(cursorPosition, event.emoji);
      quill.setSelection(cursorPosition + event?.emoji?.length);
    }
    setIsEmojiPickerOpen(false);
  };

  const removeVideo=()=>{
    setSelectedVideos('')
  }
  const  removeVideoList=()=>{
    setVideoList('')
  }

 const cancelData=()=>{
    closeModal();
    setEditorContent('');
    setSelectedImages([]);
    setSelectedVideos([]);
    setImageList([]);
    setVideoList('')
    setconfirmationBox(false)
    setTempImage([])
  }
  const closePopUp=()=>{
    setconfirmationBox(true)
  }

  const confrimBox=()=>{
    setconfirmationBox(false)
  }


    const uploadFile = async () => {
        setIsLoading(true)
        const uploadPromises = tempImage.map(async (item) => {
          const formData = new FormData();
          formData.append('file', item);
    
          try {
            const res = await PostServices.uploadFile(formData);
            setIsLoading(false)
            return { mediaTypeId: selectedImages.length > 0 ? 1 : 2, mediaUrl: res?.fileLocation };
          } catch (error) {
            setIsLoading(false)
            return null;
          }
        });
    
        const results = await Promise.all(uploadPromises);
        const successfulUploads = results.filter(result => result !== null);
  
        setImageUrl(successfulUploads);
        addPost(successfulUploads);
    }


    const addPost=(value)=>{
      let data={
          postMedia: value,
          postText: editorContent
        }
        setIsLoading(true)
        PostServices.addPost(data).then(res=>{
          setIsLoading(false)
          toast.success(res?.message)
          cancelData();
        }).catch(error=>{
          setIsLoading(false)
          toast.success(error?.message)

        })
    }

  return (
    <>
      {isModalOpen && (
        <Modal show={isModalOpen}  size={isImageModalOpen?'lg':'xl'}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
          <Modal.Body>
         <div>
              <img
                src={crossWhite}
                alt="Close"
                className={classes["close_icon"]}
                onClick={closeParentModel}
              />
              <img
                src={userImage}
                alt="User"
                className={classes["user_image"]}
              />
              <div className={classes["user_info"]}>
                <span className={classes["user_name"]}>John Doe</span>
                <img
                  src={dropdownIcon}
                  alt="Dropdown"
                  className={classes["dropdown_icon"]}
                  onClick={toggleDropdown}
                />
                {isDropdownOpen && (
                  <div className={classes["dropdown_menu"]}>
                    <div>Option 1</div>
                    <div>Option 2</div>
                    <div>Option 3</div>
                  </div>
                )}
              </div>
              <div className={classes['content-box']}>
            <div className={classes["textarea_container"]}>
              <ReactQuill
                value={editorContent}
                onChange={setEditorContent}
                ref={reactQuillRef}
                modules={{
                  toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, 
                     {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image', 'video'],
                    ['clean']
                  ],
                }}
                formats={[
                  'header', 'font', 'size',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image', 'video'
                ]}
                placeholder="Write your post here..."
              />
              {imageList?.length > 0 && (
                <div className={classes["image_preview"]}>
                  {imageList.map((image, index) => (
                    <div key={index} className={classes["image_container"]}>
                      <img src={image} alt={`Preview ${index}`} />
                      <img
                        src={crossWhite}
                        alt="Delete"
                        className={classes["delete_icon"]}
                        onClick={() => removeImage(index)}
                      />
                    </div>
                  ))}
                </div>
              )}
 
             {videoList && (
              <div className={classes["image_preview"]}>
                <div  className={classes["video_container"]}>
                  <video
                  controls
                  width="300px"
                  src={videoList}>
                  </video>
                  <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={removeVideoList}
                    />
                  </div>
                  </div>
             )}
             {isEmojiPickerOpen && (
               <div className={classes["emoji_picker_wrapper"]}>
                 <Picker onEmojiClick={onEmojiClick} />
               </div>
             )}
            </div>
            </div>
            <div className={classes["icons_container"]}>
              <div className={classes["text_with_icon"]}>
                <img title="Image Type"
                  src={textIcon}
                  alt="Text Icon"
                  className={classes["text_icon"]}
                />
                <span>Rewrite with AI</span>
              </div>
              <img
                src={dummyImageIcon}
                alt="Dummy_Image"
                className={classes["icon"]}
                onClick={() => { openImageModal('image') }}
              />
              <img onClick={() => { openImageModal('video') }} title="Video Type" src={icon1} alt="Icon 1" className={classes["icon"]} />
              <img src={icon3} alt="Icon 3" className={classes["icon"]} />
              <img src={icon4} alt="Icon 4" className={classes["icon"]} />
              <img src={emojiIcon} alt="Emoji Icon" className={classes["icon"]} onClick={toggleEmojiPicker} />
            </div>
            <div className={classes["cp_button"]}>
            <button onClick={closePopUp} className={classes['cancel-btn']}>Cancel</button>
              <button disabled={tempImage.length===0 && !editorContent} onClick={uploadFile} className={classes["post_button"]}>Post</button>
            </div>
          </div>
        </Modal.Body>
        </Modal>
     
      )}

      {isImageModalOpen && (
              <Modal show={isModalOpen}  size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
                <Modal.Body>
           <div>
            <img
              src={crossWhite}
              alt="Close"
              className={classes["close_icon"]}
              onClick={closeImageModal}
            />
            <div className={classes['select-content-box']}>
            <div className={classes["modal_header"]}>
              <h2>Select {selectedType === 'image' ? 'Images' : 'Videos'}</h2>
            </div>
            <input ref={fileInputRef} type="file" accept={selectedType === 'image' ? "image/*" : "video/*"} multiple onChange={handleImageChange} />
            {selectedType === 'image' && selectedImages.length > 0 && (
              <div className={classes["image_preview"]}>
                {selectedImages.map((image, index) => (
                  <div key={index} className={classes["image_container"]}>
                    <img src={image} alt={`Preview ${index}`} />
                    <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={() => deleteImageFromPreview(index)}
                    />
                  </div>
                ))}
              </div>
            )}

            {selectedType==='video' && selectedVideos && (
              <div className={classes["image_preview"]}>
                <div  className={classes["video_container"]}>
                  <video
                  controls
                  width="300px"
                  src={selectedVideos}>
                  </video>
                  <img
                      src={crossWhite}
                      alt="Delete"
                      className={classes["delete_icon"]}
                      onClick={removeVideo}
                    />
                  </div>
              </div>
            )}
             </div>
             <div className="text-end">
             <button onClick={insertMedia} className={classes["insert_button"]}>
              Insert {selectedType === 'image' ? 'Images' : 'Videos'}
            </button>
             </div>
         
          </div>
        </Modal.Body>
        </Modal>
      )}
         {confirmationBox && (
          <ConfirmationModal onShow={confirmationBox} getConfirmation={cancelData} 
          onClose={confrimBox} btnText='Yes'  confirmationText='Are you sure you want to leave the post ? Your changes will not be saved.'></ConfirmationModal>
        )}
        {isLoading && (
          <Loader></Loader>
        )}
        
    </>
  );
}

export default CommonPost;