import React, { useState } from 'react';
import classes from "./GroupsTabs_Info_Panel.module.css";
import iconEdit2 from "../../../../../Assets/Logo/iconEdit2.svg";

function GroupsTabs_Info_Panel({tab, setTab, name, access, members}) {

  // const [tab, setTab] = useState("Post");

  return (
    <div>
        <div className={classes["group_tabs_container"]}>
          <div className={classes["group_info"]}>

            <div className={classes["info_section"]}>
                 
               <div className={classes["info_content"]}>
                 <div className={classes["info_title"]}>
                  <span>{name}</span> 
                  <img  className={classes["edit_icon"]} src={iconEdit2} alt="" />
                  </div>
                 <div className={classes["info_description"]}> {access} | {members} Members </div>
               </div>

               <div  className={classes["info_content"]}>
                  <button className={classes["invite_button"]}>+ Invite</button>
              </div>

            </div>

            <div className={classes["nav_buttons"]}>
                <button
                  className={tab === "Post" ? classes["active_button"] : classes["nav_button"]}
                  onClick={() => setTab("Post")}
                >
                  Post
                </button>
                <button
                  className={tab === "Discussion" ? classes["active_button"] : classes["nav_button"]}
                  onClick={() => setTab("Discussion")}
                >
                  Discussion
                </button>
                <button
                  className={tab === "Members" ? classes["active_button"] : classes["nav_button"]}
                  onClick={() => setTab("Members")}
                >
                  Members
                </button>
                <button
                  className={tab === "Moderator" ? classes["active_button"] : classes["nav_button"]}
                  onClick={() => setTab("Moderator")}
                >
                  Moderator <span className={classes["moderator-count"]}>2</span>
                </button>
              </div>
            </div>
            
        </div>
    </div>
  )
}

export default GroupsTabs_Info_Panel