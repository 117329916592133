import React, { useState } from "react";
import classes from "./CreateGroup.module.css";
import searchIcon from "../../../../../Assets/Logo/search-icon-2.svg";
import dumyImageIcon from "../../../../../Assets/Logo/dummy-image-icon.svg";
import nameInfoImage from "../../../../../Assets/Images/name-info-image.png";

const CreateGroup = () => {
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [invites, setInvites] = useState([]);
  const [moderators, setModerators] = useState([]);
  const [image, setImage] = useState(null);
  const [accessLevel, setAccessLevel] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Group Created:", {
      groupName,
      description,
      invites,
      moderators,
      image,
      accessLevel,
    });
    // Logic to create the group goes here
  };

  // temperory data
  const names = [
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
    { name: "Ken lench", image: nameInfoImage },
  ];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleCheckboxChange = (name, type) => {
    // logic to added peoples in invites and in moderators
    if (type === "invite") {
      setInvites((prev) => ({ ...prev, [name]: !prev[name] })); 
    } else {
      setModerators((prev) => ({ ...prev, [name]: !prev[name] })); 
    }
  }

  return (
    <div className={` ${classes["main_create_group_container"]}`}>

        <div className={`row ${classes["create-group-form"]}`}>
          
          <div className={`${classes["create_group"]}col-md-12 col-lg-6`}>
            <div className={`${classes["form-section"]} form-group`}>
              <div className={classes["group-name-section"]}>
                <div className={classes["heading"]}>Group Name</div>
                <input
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  placeholder="Group Name"
                  required
                />
                <div className={`${classes["group-type"]}`}>
                  <div className={`${classes["radio-option"]}`}>
                    <input type="radio" name="groupType" value="public"  checked={accessLevel === "public"}
                    onChange={(e) => setAccessLevel(e.target.value)}/>
                    Public
                  </div>
                  <div className={`${classes["radio-option"]}`}>
                    <input type="radio" name="groupType" value="private" checked={accessLevel === "private"}
                    onChange={(e) => setAccessLevel(e.target.value)} />
                    Private
                  </div>
                  <div className={`${classes["radio-option"]}`}>
                    <input type="radio" name="groupType" value="exclusive" checked={accessLevel === "exclusive"}
                    onChange={(e) => setAccessLevel(e.target.value)}/>
                    Exclusive
                  </div>
                </div>
              </div>
            </div>

            <div className={`${classes["form-section"]}`}>
              <div className={classes["invite-section"]}>
                <div className={classes["heading"]}>Invite</div>

                <div className={`${classes["search_input_content"]}`}>
                  <input
                    type="text"
                    placeholder="Search..."
                    className={`${classes["search-input"]}`}
                  />
                  <img
                    src={searchIcon}
                    alt="Search"
                    className={`${classes["search-icon"]}`}
                  />
                </div>

                <div className={`${classes["list-content"]}`}>
                  <ul className={`${classes["invite-list"]}`}>
                    {names.map(({ name, image }) => (
                      <li key={name} className={classes["name-item"]}>
                        <div className={classes["name-info"]}>
                          <img
                            src={image}
                            alt={name}
                            className={classes["name-image"]}
                          />
                          <span>{name}</span>
                        </div>
                        <div className={classes["checkbox-container"]}>
                          <input
                            className={classes["checkbox-input"]}
                            type="checkbox"
                            checked="checked"
                            onChange={() => handleCheckboxChange(name, "invite")}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className={`${classes["form-section"]}`}>
              <div className={classes["heading"]}>Moderators</div>

              <div className={`${classes["list-content"]}`}>
                <ul className={`${classes["moderator-list"]}`}>
                  {names.map(({ name, image }) => (
                    <li key={name} className={classes["name-item"]}>
                      <div className={classes["name-info"]}>
                        <img
                          src={image}
                          alt={name}
                          className={classes["name-image"]}
                        />
                        <span>{name}</span>
                      </div>
                      <div className={classes["checkbox-container"]}>
                        <input
                          className={classes["checkbox-input"]}
                          type="checkbox"
                          checked="checked"
                          onChange={() => handleCheckboxChange(name, "moderator")}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className={`${classes["create_group"]} col-md-12 col-lg-6`}>
            <div className={`${classes["form-section"]}`}>
              <div className={`${classes["image-upload-section"]}`}>
                <div className={classes["heading"]}>Image</div>

                <div className={`${classes["image-upload"]}`}>
                  {!image && (
                    <div className={classes["over-lay-upload"]}>
                      <p className={classes["hover-upload"]}>
                        <img
                          className={classes["damy-image"]}
                          src={dumyImageIcon}
                          alt=""
                        />{" "}
                      </p>
                      <input
                        type="file"
                        title="Select file"
                        onChange={handleFileChange}
                        className={classes["image-upload-input"]}
                      />
                    </div>
                  )}

                  {image && (
                    <img
                      className={classes["real-image"]}
                      src={image}
                      alt="Uploaded"
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${classes["form-section"]} ${classes["description-section"]}`}
            >
              <div className={classes["heading"]}>Description</div>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description..."
                required
              />
            </div>

            <button
              type="submit"
              className={`${classes["create-group-button"]}`}
                onClick={handleSubmit}
            >
              Create Group
            </button>
          </div>
        </div>
     
    </div>
  );
};

export default CreateGroup;
