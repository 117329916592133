import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styles from './ContactInfo.module.css'
 const ContactInfo =(props)=>{
    const [counsellorName, setCounsellorName]=useState();
    const [counsellorEmail, setCounsellorEmail]=useState();
    const [counsellorMobileNumber, setCounsellorMobileNumber]=useState();
    const [referrerName, setReferrerName]=useState();
    const [referrerEmail, setReferrerEmail]=useState();

 const getCounsellorName=(value)=>{
    setCounsellorName(value)

 }
 const getCounsellorEmail=(value)=>{
    setCounsellorEmail(value)
 }
 const getCounsellorMobileNumber=(value)=>{
    setCounsellorMobileNumber(value)
 }
 const getReferrerName=(value)=>{
    setReferrerName(value)
 }
 const getReferrerEmail=(value)=>{
    setReferrerEmail(value)
 }



    return(
        <div className={styles['data-section']}>
              <Form>
                <div className={styles['heading']}>
                    <p>
                    Residential Address
                    </p>
                </div>
                <div className={styles['address']}>
                  <div className="row">
                    <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="address" >  
                        <Form.Label> Address</Form.Label>
                        <Form.Control value={counsellorName} type="text" placeholder="Address"/>
                    </Form.Group>
                    </div>

                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="country" > 
                    <Form.Label> Country</Form.Label>
                    <Form.Select aria-label="Country">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                   <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="provinceState" > 
                    <Form.Label> Province/State</Form.Label>
                    <Form.Select aria-label="Province/State">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                   <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="cityTown" > 
                    <Form.Label> City/Town</Form.Label>
                    <Form.Select aria-label="City/Town">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>


                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="postalZipCode" >  
                        <Form.Label> Postal/Zip Code</Form.Label>
                        <Form.Control value={referrerEmail} type="text" placeholder="Postal/Zip Code"/>
                    </Form.Group>
                    </div>

                </div>
                </div>

                <div className={styles['heading']}>
                    <p>
                        Mailing Address
                    </p>
                </div>
                <div className={styles['address']}>
                  <div className="row">
                    <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="address" >  
                        <Form.Label> Address</Form.Label>
                        <Form.Control value={counsellorName} type="text" placeholder="Address"/>
                    </Form.Group>
                    </div>

                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="country" > 
                    <Form.Label> Country</Form.Label>
                    <Form.Select aria-label="Country">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                   <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="provinceState" > 
                    <Form.Label> Province/State</Form.Label>
                    <Form.Select aria-label="Province/State">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                   <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="cityTown" > 
                    <Form.Label> City/Town</Form.Label>
                    <Form.Select aria-label="City/Town">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>


                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="postalZipCode" >  
                        <Form.Label> Postal/Zip Code</Form.Label>
                        <Form.Control value={referrerEmail} type="text" placeholder="Postal/Zip Code"/>
                    </Form.Group>
                    </div>

                </div>
                </div>

                <div className={styles['heading']}>
                    <p>
                    Emergency Contact
                    </p>
                </div>
                <div className={styles['address']}>
                  <div className="row">
                    <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="name" >  
                        <Form.Label> Name</Form.Label>
                        <Form.Control value={counsellorName} type="text" placeholder="Name" />
                    </Form.Group>
                    </div>

                    <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="relation" > 
                    <Form.Label> Relation</Form.Label>
                    <Form.Select aria-label="Relation">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                   <div className="col-md-4">
                    <Form.Group className="mb-3" controlId="email" >  
                        <Form.Label> Email</Form.Label>
                        <Form.Control value={counsellorMobileNumber} type="text" placeholder="Email" />
                    </Form.Group>
                    </div>

                    <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="countryCode" > 
                    <Form.Label> Country Code</Form.Label>
                    <Form.Select aria-label="Country Code">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                    </div>


                    <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="phoneNumber" >  
                        <Form.Label> Phone Number</Form.Label>
                        <Form.Control value={referrerEmail} type="text" placeholder="Phone Number" />
                    </Form.Group>
                    </div>
                    <div className="col-md-12">
                    <Form.Group className="mb-3" controlId="address" >  
                        <Form.Label> Address</Form.Label>
                        <Form.Control value={counsellorName} type="text" placeholder="Address"/>
                    </Form.Group>
                    </div>


                </div>
                </div>
                </Form>
        </div>
    )
 }

 export default ContactInfo;