import React, { useState } from "react";
import { Button, Card, Table } from 'react-bootstrap';
import classes from "./Refunds.module.css";
import srName from "../../../../../Assets/Logo/srnum.svg";
import sortName from "../../../../../Assets/Logo/sort.svg";
import corssIcon from "../../../../../Assets/Logo/cross-icon.svg";
import SearchModal from "../../Search/SearchModal/SearchModal";
const Refunds = () => {
  const tableData = {
    columns: ["Application ID", "Student Name", "Program", "Institution", "Status"],
    rows:[
        {
          "Application ID": "8675309",
          "Student Name": "Demo Student Student",
          "Program": "Bachelor of Business Administration",
          "Institution": "Cape Breton University",
          "Status": "Paid",
          "Action": "Request Refund"
        },
        {
          "Application ID": "8675308",
          "Student Name": "Demo Student Student",
          "Program": "Bachelor of Emergency Management",
          "Institution": "Cape Breton University",
          "Status": "Paid",
          "Action": "Request Refund"
        },
        {
          "Application ID": "8675307",
          "Student Name": "Demo Student Student",
          "Program": "Bachelor of Engineering",
          "Institution": "Cape Breton University",
          "Status": "Paid",
          "Action": "Request Refund"
        }
      ],      
  };
  const [showModal, setShowModal] = useState(false);
  const handleViewRequest = (id) => {
    setDeferralId(id);
    setShowModal(true);
  };
  const handleConfirm = () => {
    console.log("Refund confirmed for Application ID:", deferralId);
    setShowModal(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const [deferralId, setDeferralId] = useState(null);
  const selectedDeferral = tableData.rows.find(row => row["Application ID"] === deferralId);  
  return (
    <div className={classes.container}>
      <div className={classes.heading}>MSM Refunds</div>
      <Table responsive className={classes.customTable}>
        <thead>
          <tr>
            {tableData.columns.map((column, index) => (
              <th key={index}>
                <div className={classes.thAndIconContainer}>
                  <span className={classes.columnText}>{column}</span>
                  <img
                    src={index === 0 ? srName : sortName}
                    alt={index === 0 ? "Sr Name" : "Sort"}
                    className={classes.icon}
                  />
                </div>
              </th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {tableData.columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
              <td>
                <span 
                  className={classes.actionBtn}
                  onClick={() => handleViewRequest(row["Application ID"])}>
                  Request Refund
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table> 
      <SearchModal
        onShow={showModal}
        size="md"
        >  
        <div className={classes.ModelContainer}>
        <div className={`${classes.headerContainer}`}>
            <img
              title="Remove"
              className={classes.corssIcon}
              src={corssIcon}
              alt=""
              onClick={handleClose}></img>
        </div>
          <div className={classes.title}>Are you sure you want to refund this application?</div>
        <div className={classes.btnContainer}>
          <Button
          className={`${classes.btnNo}`}
            onClick={handleClose}>
            No
          </Button>
          <Button
          className={`${classes.btnYes}`}
            onClick={handleConfirm}>
            Yes
          </Button>
        </div>
        </div> 
      </SearchModal>
   </div>
 );
};

export default Refunds;
