 import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styles from './AssignedCounsellor.module.css'
 const AssignedCounsellor =(props)=>{
    const [counsellorName, setCounsellorName]=useState();
    const [counsellorEmail, setCounsellorEmail]=useState();
    const [counsellorMobileNumber, setCounsellorMobileNumber]=useState();
    const [referrerName, setReferrerName]=useState();
    const [referrerEmail, setReferrerEmail]=useState();

 const getCounsellorName=(value)=>{
    setCounsellorName(value)

 }
 const getCounsellorEmail=(value)=>{
    setCounsellorEmail(value)
 }
 const getCounsellorMobileNumber=(value)=>{
    setCounsellorMobileNumber(value)
 }
 const getReferrerName=(value)=>{
    setReferrerName(value)
 }
 const getReferrerEmail=(value)=>{
    setReferrerEmail(value)
 }



    return(
        <div className={styles['data-section']}>
              <Form>
                <div className="row">
                    <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="counsellorName" >  
                        <Form.Label> Counsellor Name</Form.Label>
                        <Form.Control value={counsellorName} type="text" placeholder="Counsellor Name" onChange={(e)=>{getCounsellorName(e.target.value)}}/>
                    </Form.Group>
                    </div>

                    <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="counsellorEmail" >  
                        <Form.Label> Counsellor Email</Form.Label>
                        <Form.Control value={counsellorEmail} type="email" placeholder="Counsellor Email" onChange={(e)=>{getCounsellorEmail(e.target.value)}}/>
                    </Form.Group>
                   </div>

                   <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="counsellorMobileNumber" >  
                        <Form.Label> Counsellor Mobile Number</Form.Label>
                        <Form.Control value={counsellorMobileNumber} type="text" placeholder="Counsellor Mobile Number" onChange={(e)=>{getCounsellorMobileNumber(e.target.value)}}/>
                    </Form.Group>
                    </div>

                    <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="referrerName" >  
                        <Form.Label> Referrer Name</Form.Label>
                        <Form.Control value={referrerName} type="text" placeholder="Referrer Name" onChange={(e)=>{getReferrerName(e.target.value)}}/>
                    </Form.Group>
                    </div>


                    <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="referrerEmail" >  
                        <Form.Label> Referrer email</Form.Label>
                        <Form.Control value={referrerEmail} type="text" placeholder="Referrer Email" onChange={(e)=>{getReferrerEmail(e.target.value)}}/>
                    </Form.Group>
                    </div>

                </div>

                </Form>
        </div>
    )
 }

 export default AssignedCounsellor;