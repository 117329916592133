import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import classes from './ApplicationCategory.module.css';
import corssIcon  from '../../../Assets/Logo/cross-icon.svg'

const ApplicationCategory =(props)=>{
    const [checkboxValue, setcheckboxValue]=useState()

    const getValue=(value)=>{
        setcheckboxValue(value)
    }

    const navigate=()=>{
        props.navigate(checkboxValue);
        props.onClose(false)
    }
     const onClose=()=>{
    props.onClose(false)
 }
    return(
        <div>
            <Modal show={props?.onShow}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered >
            <Modal.Body>
                <div className={classes['cross-icon-position']}>
                <img title="Close" className={classes["corss-icon"]} src={corssIcon} alt='' onClick={onClose}></img>
                </div>
                <div className={classes['modal-body-data']}>
                    <Form>
                    <Form className={classes["radio-button-block"]}>
                        <Form.Check
                         
                            label="On Campus"
                            type="radio"
                            id="radio-1"
                            name="category"
                            value={checkboxValue}
                            onChange={()=>{getValue(1)}}
                            className={classes["radio-btn"]}
                        />
                        <Form.Check
                            label="Online/Blended"
                            type="radio"
                            id="radio-2"
                            name="category"
                            value={checkboxValue}
                            onChange={()=>{getValue(2)}}
                            className={classes["radio-btn"]}
                        />
                    </Form>

                    </Form>
                </div>
               
                <div className={classes['btn-position']}>
                <button className='primary-button' onClick={navigate}>OK</button>
                </div>

            </Modal.Body>

        </Modal>
        </div>
    )
}

export default ApplicationCategory;