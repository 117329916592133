import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styles from './EducationSection.module.css'
 const EducationSection =(props)=>{
    const [counsellorName, setCounsellorName]=useState();
    const [counsellorEmail, setCounsellorEmail]=useState();
    const [counsellorMobileNumber, setCounsellorMobileNumber]=useState();
    const [referrerName, setReferrerName]=useState();
    const [referrerEmail, setReferrerEmail]=useState();


    return(
        <div className={styles['data-section']}>
              <Form>
                <div className={styles['address']}>
                  <div className="row">
                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="countryofEducation" > 
                    <Form.Label> Country of Education</Form.Label>
                    <Form.Select aria-label="Country of Education">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                    </div>

                    <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="highestLevelofEducation" > 
                    <Form.Label> Highest Level of Education</Form.Label>
                    <Form.Select aria-label="Highest Level of Education">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                   <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="gradingScheme" > 
                    <Form.Label> Grading Scheme</Form.Label>
                    <Form.Select aria-label="Grading Scheme">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                   <div className="col-md-3">
                    <Form.Group className="mb-3" controlId="gradeAverage" > 
                    <Form.Label> Grade Average</Form.Label>
                    <Form.Select aria-label="Grade Average">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    </Form.Select>
                    </Form.Group>
                   </div>

                </div>
                </div>
                </Form>

                <div className="text-end mt-2">
                    <button className={styles['add-more-btn']}>+ Add More Education</button>
                </div>
        </div>
    )
 }

 export default EducationSection;