
import React, { useState, useRef, useEffect } from "react";
import classes from "./Posts.module.css";
import PostPic from "../../../Assets/Images/post_user_pic.png";
import PostImage from "../../../Assets/Images/post_image.png";
import TripleDot from "../../../Assets/Logo/triple_dot.svg";
import PostCrossIcon from "../../../Assets/Logo/post_cross_icon.svg";
import CommentPic from "../../../Assets/Images/comment_user_pic.png";
import Like from "../../../Assets/Logo/like.svg";
import Liked from "../../../Assets/Logo/liked.svg"; 
import Comment from "../../../Assets/Logo/comment.svg";
import Share from "../../../Assets/Logo/share.svg";

import useLocalStorage from "../../GlobleShared/CustomHooks/useLocalStorage";

function InnerPost({ post }) {
  const [moreBtnPopupOpen, setMoreBtnPopupOpen] = useState(false);
  const [viewComments, setViewComments] = useState(3);
  const [CommentBox, setCommentBox] = useState(false);
  const [comment, setComment] = useState('');

  const [userDetail] = useLocalStorage('userDetail');


  const userId = userDetail.user_id;
  
  const [liked, setLiked] = useState(post.likedBy.includes(userId));
  const moreBtnRef = useRef(null);


  const handleCommentSubmit = () => {
    if (comment.trim()) {
    post.comments.push({ authorId: userDetail.user_id, author: `${userDetail.first_name} ${userDetail.last_name}` , text: comment, commentPic: PostPic });
    console.log(post.comments);
    setComment('');
    setCommentBox(false);
    }
  };


   const showCommentBox=()=>{
    setCommentBox(!CommentBox)
   }
   
  const handleViewMoreComments = () => {
    setViewComments((prev) => prev + 4);
  };

  const handleShowLessComments =()=>{
    setViewComments(3)
  }

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const toggleMoreBtnPopup = () => {
    setMoreBtnPopupOpen(true);
  };

  const handleLike = () => {
    if (liked) {
      post.likedBy = post.likedBy.filter(id => id !== userId);
    } else {
      post.likedBy.push(userId);
    }
    setLiked(!liked);
  };


  useEffect(() => {
    function handleClickOutside(event) {
      if (moreBtnRef.current && !moreBtnRef.current.contains(event.target)) {
        setMoreBtnPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreBtnRef]);

  return (
    <div className={classes["post-container"]}>
    <div className={classes["post-block"]}>
      <div className={classes["header"]}>
        <div className={classes["header-left"]}>
          <img
            src={post.profilePicture}
            alt="Profile-Picture" 
            className={classes["profile_picture"]}
          />
          <div className={classes["user_info"]}>
            <div className={classes["user_name"]}>{post.userName}</div>
            <div className={classes["user_role"]}>{post.userRole}</div>
          </div>
        </div>
        <div className={classes["header-right"]}>
            <span className={classes["triple-dot"]}
            onClick={() => toggleMoreBtnPopup()}>
              <img src={TripleDot} alt="" />
            </span>
            <span className={classes["cross-icon"]}>
              <img src={PostCrossIcon} alt="" />
            </span>
            {moreBtnPopupOpen && (
              <div className={classes["MoreBtn"]} ref={moreBtnRef}>
                <div className={classes["MoreBtnItem"]}>Edit</div>
                <div className={classes["brokenLine"]}></div>
                <div className={classes["MoreBtnItem"]}>Delete</div>
                <div className={classes["brokenLine"]}></div>
                <div className={classes["MoreBtnItem"]}>Repost</div>
                <div className={classes["brokenLine"]}></div>
                <div className={classes["MoreBtnItem"]}>Comment</div>
              </div>
            )}
          </div>
        </div>

      <div className={classes["content"]}>
        <div className={classes["post_text"]}>{post.text}</div>
      </div>

      <div className={classes["post-image"]}>
        <img src={post.image} alt="" />
      </div>

      <div className={classes["actions"]}>
        <button className={classes["action_button"]}
          onClick={()=>handleLike()}
        >
          <img src={liked ? Liked : Like} alt="" /> <span className={liked ? classes["activeLikeKeyword"] : ''}>Like</span>
        </button>
        <button onClick={showCommentBox} className={classes["action_button"]}>
          <img src={Comment} alt="" /> Comments ({post.comments.length})
        </button>
        <button className={classes["action_button"]}>
          <img src={Share} alt="" /> Share
        </button>
      </div>

      { CommentBox && (
        <>
      <div className={classes['add-comment-container']}>
        <img src={PostPic} alt="User" className={classes['user-pic']} />
        <input
          type="text"
          placeholder="Add a comment..."
          value={comment}
          onChange={handleCommentChange}
          className={classes['input-field']}
        />
        <button onClick={handleCommentSubmit} className={classes['comment-btn']}>
          Comment
        </button>
      </div>
      </>)}

      <div className={classes["comments_section"]}>
        <div className={classes["comments_title"]}>
          <div className={classes["text"]}>Comments:</div>
          {viewComments < post.comments.length ? (
              <div onClick={handleViewMoreComments} className={classes["view_more"]}>
                view more...
              </div>
            ) : (
              viewComments > 3 && (
                <div onClick={handleShowLessComments} className={classes["view_more"]}>
                  show less...
                </div>
              )
            )}
        </div>

        <div className={classes["comments"]}>
          {post.comments.slice(0, viewComments).map((comment, index) => (
            <div className={classes["comment"]} key={index}>
              <div className={classes["comment-image"]}>
                <img src={comment.commentPic} alt="" /> 
              </div>

              <div className={classes["comment_content"]}>
                <div className={classes["comment_author"]}>
                  {comment.author}:
                </div>
                <div className={classes["comment_text"]}>
                  {comment.text}
                </div>
              </div>
            </div>
          ))}
        </div>



         

      </div>
    </div>
    </div>

  );
}

function Posts() {

  // create temperaray Sample array of post data

  const posts = [
    {
      id: 1,
      profilePicture: PostPic,
      userName: "Jane Smith",
      userRole: "Study Group",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minima cumque, aspernatur laboriosam quo velit nulla.",
      image: PostImage,
      likedBy: [7,2],
      comments: [
        { authorId: 1, author: "Emily Davis", text: "Awesome! Keep it up!", commentPic: CommentPic },
        { authorId: 2, author: "Michael Brown", text: "Motivating!", commentPic: CommentPic },
        { authorId: 3, author: "Michael Brown", text: "Motivating!", commentPic: CommentPic },
        { authorId: 4, author: "Michael Brown", text: "Motivating!", commentPic: CommentPic },
        { authorId: 5, author: "John Doe", text: "Great post!", commentPic: CommentPic },
        { authorId: 6, author: "Sarah Connor", text: "Keep it up!", commentPic: CommentPic },
        // Add more comments as needed
      ],
    },
    {
      id: 2,
      profilePicture: PostPic,
      userName: "Mark Johnson",
      userRole: "Fitness Enthusiast",
      text: "Just finished a great workout session! Feeling energized.",
      image: PostImage,
      likedBy: [12],
      comments: [
        { authorId: 1, author: "Emily Davis", text: "Awesome! Keep it up!", commentPic: CommentPic },
        { authorId: 2, author: "Michael Brown", text: "Motivating!", commentPic: CommentPic },
        { authorId: 3, author: "Michael Brown", text: "Motivating!", commentPic: CommentPic },
        { authorId: 4, author: "Michael Brown", text: "Motivating!", commentPic: CommentPic },
        { authorId: 5, author: "John Doe", text: "Great post!", commentPic: CommentPic },
        { authorId: 6, author: "Sarah Connor", text: "Keep it up!", commentPic: CommentPic },
        // Add more comments as needed
      ],
    },
  ];

  return (
    <div className={classes["post-main-container"]}>
      {posts.map((post, index) => (
        <InnerPost key={index} post={post} /> 
      ))}
    </div>
  );
}

export default Posts;
