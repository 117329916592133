import React, { useState } from "react";
import GroupsHeader from "../GroupsHeader/GroupsHeader";
import GroupsTabs from "../GroupsTabs_Info_panel/GroupsTabs_Info_Panel";
import Posts from "../../../../Common/Posts/Posts";
import ComposePost from "../ComposePost/ComposePost";
import About from "../About/About";
import classes from "./GroupsDashboard.module.css";
import backgroundImage from "../../../../../Assets/Images/groups_main_background_image.png";
import GroupMembers from "../GroupsMembers/GroupMembers";

function GroupsDashboard() {
  const [tab, setTab] = useState("Post");

  //here we are using temperary data but we use data comes from api.
  const groupDetails = {
    groupName: "The New New",
    groupAccess: "Private",
    groupDescription:
      " A new place to talk about whats new and happening in  global. Take part in the discussion and see whats happening.",
    image: { backgroundImage },
    membersCount: 14,
  };

  return (
    <div>
      <div className={`${classes["groups_dashboard_sections"]} `}>
        <GroupsHeader image={groupDetails.image} />
        <GroupsTabs
          tab={tab}
          setTab={setTab}
          name={groupDetails.groupName}
          access={groupDetails.groupAccess}
          members={groupDetails.membersCount}
        />
        <div className={classes["compose-post-container"]}>
          <div>
          {tab === "Post" && (
            <div className={classes["compose-posts"]}>
              <ComposePost />
              <Posts />
            </div>
          )}

          {tab === "Members" && (
            <div className="row">
              <div  className=" col-md-12 col-lg-7 p-0">
                <GroupMembers />
              </div>
              <div className="col-md-12 col-lg-5 p-0"></div>
            </div>
          )}
          </div>

          <div className={classes["about-container"]}>
            <About description={groupDetails.groupDescription} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupsDashboard;
