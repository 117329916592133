import React, { useEffect, useRef, useState } from "react";
import classes from "./GroupProfiles.module.css";
import addIcon from "../../../../../Assets/Logo/add-icon.svg";
import moreIcon from "../../../../../Assets/Logo/more-icon.svg";
import crownICon from "../../../../../Assets/Logo/crown.svg";
import reportICon from "../../../../../Assets/Logo/report-icon.svg";
import leaveICon from "../../../../../Assets/Logo/Leave.svg";
import blockICon from "../../../../../Assets/Logo/Report.svg";
import pauseICon from "../../../../../Assets/Logo/pause.svg";

const GroupProfiles = ({ name, update, image, id, index, groupLength, groupType, tab, member, crown}) => {
  const [moreBtnPopUp, setMoreBtnPopUp] = useState(false);
  const dropdownRef = useRef();
  function onClickOutsideHandler(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMoreBtnPopUp(false);
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutsideHandler);
    return () => {
        document.removeEventListener('mousedown', onClickOutsideHandler);
    };
  }, []);
  return (
    <div>
      <div
        key={id}
        className={classes["group-item"]}>
        <img
          src={image}
          alt={name}
          className={classes["group-image"]}
        />
        <div className=   {tab === "your-group" ? classes["group-details"] : classes["group-detailsD"]}>
          <span className={classes.nameAndCrown}><h3 className={classes["group-name"]}>{name}</h3>{crown && <img className = {classes["imgCrown"]} src={crownICon}/>} </span>
          {groupType != null && <div className={classes["group-update"]}>{groupType}</div>}
          {tab === "your-group" ? <div className={classes["group-update"]}>Update: {update}</div> : <div className={classes["group-member"]}>{member} member</div>}
        </div>
        <div className={classes["group-actions"]}>
          {tab === "your-group" ? (
            <>
            <button className={classes["action-button"]}>
            <img
              src={addIcon}
              alt="Add"
              className={classes["icon"]}
              />
          </button>
          <button className={classes["action-button"]}>
            <img
              src={moreIcon}
              alt="More"
              className={classes["icon"]}
              onClick={()=>setMoreBtnPopUp(true)}
              />
          </button>
            {moreBtnPopUp && (
             <div className={classes["moreBtnParent"]} ref={dropdownRef}>
                <div className={classes["moreBtn"]}>
                           <div className={classes["moreBtnItem"]}><div><img className={classes.imgPadding}src={leaveICon}/></div><div>Leave</div></div>
                           <div className={classes["moreBtnItem"]}><div><img className={classes.imgPadding}src={reportICon}/></div><div>Report</div></div>
                           <div className={classes["moreBtnItem"]}><div><img className={classes.imgPadding}src={blockICon}/></div><div>Block</div></div>
                           <div className={classes["moreBtnItem"]}><div><img className={classes.imgPadding}src={leaveICon}/></div><div>Delete</div></div>
                           <div className={classes["moreBtnItem"]}><div><img className={classes.imgPadding}src={pauseICon}/></div><div>Pause</div></div>
                </div>
             </div>
            )}
          </>
            ) : (
          <>
            {tab === "requests"?(
              <><span className={classes["withdrawButton"]}>Withdraw</span></>
              ):(
              <><button className={classes["primary-button"]}>Join</button></>
              )}
          </>) }
        </div>
      </div>
      {index < groupLength - 1 ? <div className={classes["brokenLine"]}></div> : <div className="mb-4"></div>}
    </div>
  );
};

export default GroupProfiles;
