import React from "react";
import classes from "./Institutes.module.css";
import companyLogoOne from "../../../../../Assets/Logo/comp-logo-1.svg";
import companyLogoTwo from "../../../../../Assets/Logo/comp-logo-2.svg";
import crossIconWhite from "../../../../../Assets/Logo/crose-whiteMini.svg";
import Accordion from "react-bootstrap/Accordion";
import { Form } from "react-bootstrap";
import InstitutesCard from "../../../../Common/InstitutesCard/InstitutesCard";
const Institutes = () => {
  const institutesData = [
    {
      id: 1,
      name: "Douglas College",
      landmark: "New Westminster,",
      location: "British Columbia, Canada",
      account:"Accounting",
      img: companyLogoOne,
      backgroundColor: "#000000",
      ranked: 1,
      intake: {
        startDate: "Sep 2024",
        endDate: "Jan 2025",
        duration: "2 Years",
      },
      degree: "Bachelors Degree",
      degreeFee: "CAD $14,110/Year",
      applicationFee: "CAD $30",
      graduates: "403 of 435 graduated",
      graduationLikelihood: "95%",
      description:
        "Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program. Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...",
      },
      {
      id: 2,
      name: "Simon Frasier",
      img: companyLogoTwo,
      backgroundColor: "rgba(171, 26, 45, 1)",
      landmark: "Burnaby,",
      location: "British Columbia, Canada",
      account:"Finances",
      ranked: 4,
      intake: {
        startDate: "Sep 2024",
        endDate: "Jan 2025",
        duration: "4 Years",
      },
      degree: "Bachelors Degree",
      degreeFee: "CAD $14,110/Year",
      applicationFee: "CAD $30",
      graduates: "403 of 435 graduated",
      graduationLikelihood: "95%",
      description:
        "Students can for junior- to intermediate-level accounting positions with the two-year Accounting Diploma program. Students will study a well-rounded curriculum that includes classes in accounting, communications, computing...",
    },
  ];
  const disciplines = ["Biology", "Chemistry", "Physics", "Astronomy", "Finances", "Law", "Psychology", "Linguistics", "Finances", "Law", "Psychology", "Linguistics", "Finances", "Law", "Psychology"];

  return (
    <div className="row">
      <div className="col-md-2">
        <Accordion
          className="filter-section"
          defaultActiveKey={["0", "1", "2"]}
          alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Discipline</Accordion.Header>
            <Accordion.Body>
              <Form>
                <input
                  type="text"
                  className={classes.searchInput}
                  placeholder="Search a Discipline..."
                />
                {disciplines.map((discipline, index) => (
                  <Form.Check
                    type="checkbox"
                    id="ct-onCampus"
                    label={discipline}
                  />
                ))}
              </Form>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Duration</Accordion.Header>
            <Accordion.Body>
              <Form>
                <Form.Check
                  type="checkbox"
                  id="tutionFee1"
                  label="$1000 - $5000"
                />
              </Form>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Course Type</Accordion.Header>
            <Accordion.Body>
              <Form>
                <Form.Check
                  type="checkbox"
                  id="duration-4year"
                  label="4 year"
                />
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="col-md-10">
        <div className="d-flex">
        {institutesData.map((rowData) => (
              <button className={classes.NameBudge}>
                {" "}
                {rowData.name}
                <span>
                  {" "}
                  <img
                    src={crossIconWhite}
                    alt=""
                  />
                </span>
              </button>
        ))}
        </div>
        <div className={`${classes.container}`}>
        {institutesData.map((institute) => (
            <InstitutesCard key={institute.id} institute={institute}/>
          ))}
          </div>
        </div>
      </div>
  );
};

export default Institutes;
